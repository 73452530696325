import React from 'react';
import { Outlet } from 'react-router-dom';
import HasRights from '../components/HasRights';
import { Permissions } from '../const/permissions';
import urlPageLogin from '../urls/urlPageLogin';
import urlPageSpaceDashboard from '../urls/urlPageSpaceDashboard';
import urlPageProjectDashboard from '../urls/urlPageProjectDashboard';
import urlPageUserEvents from '../urls/urlPageUserEvents';
import urlPageUserProfileDetailsDevice from '../urls/urlPageUserProfileDetailsDevice';
import urlPageUserProfileDetailsDeviceAbTests from '../urls/urlPageUserProfileDetailsDeviceAbTests';
import urlPageUserProfileDetailsDevicePurchases from '../urls/urlPageUserProfileDetailsDevicePurchases';
import urlPageUserProfileDetailsDevicePics from '../urls/urlPageUserProfileDetailsDevicePics';
import urlPageEventsSequence from '../urls/urlPageEventsSequence';
import urlPageMonitoring from '../urls/urlPageMonitoring';
import urlPageGamesMonitor from '../urls/urlPageGamesMonitor';
import urlPageAppsMonitor from '../urls/urlPageAppsMonitor';
import urlPageAdsMonitor from '../urls/urlPageAdsMonitor';
import urlPageHintsMonitor from '../urls/urlPageHintsMonitor';
import urlPageContactUsMonitor from '../urls/urlPageContactUsMonitor';
import urlPageAdministration from '../urls/urlPageAdministration';
import urlPageAbTests from '../urls/urlPageAbTests';
import urlPageUsers from '../urls/urlPageUsers';
import urlPageLogs from '../urls/urlPageLogs';
import urlPageLogDetails from '../urls/urlPageLogDetails';
import urlPageTicketDetails from '../urls/urlPageTicketDetails';
import urlPagePermissions from '../urls/urlPagePermissions';
import urlPageRoles from '../urls/urlPageRoles';
import urlPageSupport from '../urls/urlPageSupport';
import urlPageTickets from '../urls/urlPageTickets';
import urlPageConfigurations from '../urls/urlPageConfigurations';
import urlPageStructures from '../urls/urlPageStructures';
import urlPageConfigVisualize from '../urls/urlPageConfigVisualize';
import urlPageConfigMixinVisualize from '../urls/urlPageConfigMixinVisualize';
import urlPageConfigVisualizeCreate from '../urls/urlPageConfigVisualizeCreate';
import urlPageConfigVisualizeDetails from '../urls/urlPageConfigVisualizeDetails';
import urlPageCreateMixinsExperiment from '../urls/urlPageCreateMixinsExperiment';
import urlPageEditMixinsExperiment from '../urls/urlPageEditMixinsExperiment';
import urlPageMixinsExperimentDetails from '../urls/urlPageMixinsExperimentDetails';
import urlPageCreateMixinsExperimentMixinTargeting from '../urls/urlPageCreateMixinsExperimentMixinTargeting';
import urlPageEditMixinsExperimentTargeting from '../urls/urlPageEditMixinsExperimentTargeting';
import urlPageCreateMixinsExperimentsMixin from '../urls/urlPageCreateMixinsExperimentsMixin';
import urlPageEditMixinsExperimentMixin from '../urls/urlPageEditMixinsExperimentMixin';
import urlPageCreateAbTest from '../urls/urlPageCreateAbTest';
import urlPageEditAbTest from '../urls/urlPageEditAbTest';
import urlPageAbTestDetails from '../urls/urlPageAbTestDetails';
import urlPageConfigurationsCreate from '../urls/urlPageConfigurationsCreate';
import urlPageConfigurationDetails from '../urls/urlPageConfigurationDetails';
import urlPageEditConfiguration from '../urls/urlPageEditConfiguration';
import urlPageCreateConfigTargeting from '../urls/urlPageCreateConfigTargeting';
import urlPageEditConfigTargeting from '../urls/urlPageEditConfigTargeting';
import urlPageCreateConfigMixin from '../urls/urlPageCreateConfigMixin';
import urlPageConfigMixinDetails from '../urls/urlPageConfigMixinDetails';
import urlPageCreateConfigMixinTargeting from '../urls/urlPageCreateConfigMixinTargeting';
import urlPageEditConfigMixinTargeting from '../urls/urlPageEditConfigMixinTargeting';
import urlPageUserDetails from '../urls/urlPageUserDetails';
import urlPageMixinVisualize from '../urls/urlPageMixinVisualize';
import urlPageUserProfiles from '../urls/urlPageUserProfiles';
import urlPageUserProfileDetails from '../urls/urlPageUserProfileDetails';
import urlPageStructureDetails from '../urls/urlPageStructureDetails';
import urlPageCreateProject from '../urls/urlPageCreateProject';
import urlPageEditProject from '../urls/urlPageEditProject';
import urlPageRoleDetails from '../urls/urlPageRoleDetails';
import urlPageProjects from '../urls/urlPageProjects';
import urlPageSpaces from '../urls/urlPageSpaces';
import urlPageSpaceProjects from '../urls/urlPageSpaceProjects';
import urlPageSpaceCreateProject from '../urls/urlPageSpaceCreateProject';
import urlPageSpaceEditProject from '../urls/urlPageSpaceEditProject';
import urlPageProjectUserDetails from '../urls/urlPageProjectUserDetails';
import urlPageConsentProvider from '../urls/urlPageConsentProvider';
import urlPageEditConfigMixin from '../urls/urlPageEditConfigMixin';
import urlPageSettings from '../urls/urlPageSettings';
import urlPageSettingsUsers from '../urls/urlPageSettingsUsers';
import urlPageSettingsUserDetails from '../urls/urlPageSettingsUserDetails';
import urlPageSettingsLogs from '../urls/urlPageSettingsLogs';
import urlPageSettingsLogDetails from '../urls/urlPageSettingsLogDetails';
import urlPageSettingsPermissions from '../urls/urlPageSettingsPermissions';
import urlPageSettingsRoles from '../urls/urlPageSettingsRoles';
import urlPageSettingsRoleDetails from '../urls/urlPageSettingsRoleDetails';
import urlPageSettingsProjects from '../urls/urlPageSettingsProjects';
import urlPageSettingsCreateProject from '../urls/urlPageSettingsCreateProject';
import urlPageSettingsEditProject from '../urls/urlPageSettingsEditProject';
import urlPageMixinsExperiments from '../urls/urlPageMixinsExperiments';
import PageTicketDetails from '../domains/Support/pages/PageTicketDetails';
import PageUserEvents from '../domains/Support/pages/UserEvents/PageUserEvents';
import PageUserProfileListDevices from '../domains/UserProfiles/pages/PageUserProfileListDevices';
import PageUserProfileDevice from '../domains/UserProfiles/pages/PageUserProfileDevice';
import PageUserProfileDetailsDeviceDetails from '../domains/UserProfiles/pages/PageUserProfileDetailsDeviceDetails';
import PageEventsSequence from '../domains/Support/pages/EventsSequence/PageEventsSequence';
import PageGamesMonitor from '../domains/Monitor/pages/GamesMonitor';
import PageApplicationMonitor from '../domains/Monitor/pages/ApplicationMonitor';
import PageAdsMonitor from '../domains/Monitor/pages/AdsMonitor';
import PageHintsMonitor from '../domains/Monitor/pages/HintsMonitor';
import PageContactUsMonitor from '../domains/Monitor/pages/ContactUsMonitor';
import PageAbTests from '../domains/AbTests/pages/PageAbTests';
import PageUsers from '../domains/Administration/Users/pages/PageUsers';
import PagePermissions from '../domains/Administration/Permissions/pages/PagePermissions';
import PageRoles from '../domains/Administration/Roles/pages/PageRoles';
import PageTickets from '../domains/Support/pages/PageTickets';
import PageConfigurations from '../domains/Configurations/pages/PageConfigurations';
import PageMixinsExperiments from '../domains/Experiments/pages/PageMixinsExperiments';
import PageStructures from '../domains/Configurations/pages/PageStructures';
import PageConfigVisualize from '../domains/Configurations/pages/PageConfigVisualize';
import PageCreateConfigVisualize from '../domains/Configurations/pages/PageCreateConfigVisualize';
import PageLogin from '../app/auth';
import PageSelectProject from '../domains/Public/PageSelectProject';
import PageDashboard from '../domains/Public/PageDashboard';
import PageCreateEditMixinsExperiment from '../domains/Experiments/pages/PageCreateEditMixinsExperiment';
import PageMixinsExperimentDetails from '../domains/Experiments/pages/PageMixinsExperimentDetails';
import PageCreateEditMixinsExperimentTargeting from '../domains/Experiments/pages/PageCreateEditMixinsExperimentTargeting';
import PageCreateEditMixinsExperimentMixin from '../domains/Experiments/pages/PageCreateEditMixinsExperimentMixin';
import PageCreateEditAbTest from '../domains/AbTests/pages/PageCreateEditAbTest';
import PageAbTestDetails from '../domains/AbTests/pages/PageAbTestDetails';
import PageCreateEditConfiguration from '../domains/Configurations/pages/PageCreateEditConfiguration';
import PageConfigurationDetails from '../domains/Configurations/pages/PageConfigurationDetails';
import PageCreateEditConfigTargeting from '../domains/Configurations/pages/PageCreateEditConfigTargeting';
import PageCreateEditConfigMixin from '../domains/Configurations/pages/PageCreateEditConfigMixin';
import PageConfigurationMixinDetails from '../domains/Configurations/pages/PageConfigurationMixinDetails';
import PageCreateEditConfigMixinTargeting from '../domains/Configurations/pages/PageCreateEditConfigMixinTargeting';
import PageUserDetails from '../domains/Administration/Users/pages/PageUserDetails';
import PageStructureDetails from '../domains/Configurations/pages/PageStructures/PageStructureDetails';
import PageEditRole from '../domains/Administration/Roles/pages/PageEditRole';
import HorizontalLayout from '../components/Layout/HorizontalLayout';
import PageProjects from '../domains/Administration/Projects/pages/PageProjects';
import PageSpaces from '../domains/Administration/Spaces/pages/PageSpaces';
import PageCreateEditProject from '../domains/ConsentProvider/pages/PageCreateEditProject';
import PageLogs from '../domains/Administration/Logs/pages/PageLogs';
import PageLogDetails from '../domains/Administration/Logs/pages/PageLogDetails';
import PageConsentProvider from '../domains/ConsentProvider/pages/PageConsentProvider';
import PageProjectUserDetails from '../domains/ConsentProvider/pages/PageProjectUserDetails';
import PageMixinVisualize from '../domains/Configurations/pages/PageMixinVisualize';
import PageUserProfiles from '../domains/UserProfiles/pages/PageUserProfiles';
import PageUserProfileDetails from '../domains/UserProfiles/pages/PageUserProfileDetails';
import RequireAuth from '../app/auth/RequireAuth';
import Error404 from '../components/Error404';
import urlPageSegments from '../urls/urlPageSegments';
import PageSegments from '../domains/Segments/pages/PageSegments';
import PageSegmentDetails from '../domains/Segments/pages/PageSegmentDetails/PageSegmentDetails';
import urlPageSegmentDetails from '../urls/urlPageSegmentDetails';
import PageConfigHashVisualize from '../domains/Configurations/pages/PageConfigHashVisualize';
import urlPageConfigHashVisualize from '../urls/urlPageConfigHashVisualize';



const routes = [
  {
    path: urlPageLogin(),
    element: <PageLogin />,
  },
  {
    path: '/',
    element: <RequireAuth><HorizontalLayout /></RequireAuth>,
    children: [
      {
        path: '/',
        element: <PageSelectProject />,
      },
      {
        path: '/dashboard',
        element: '',
      },
      {
        path: urlPageSpaceDashboard(),
        element: <PageSelectProject />,
      },
      {
        path: urlPageProjectDashboard(),
        element: <PageDashboard />,
      },
      {
        path: urlPageUserProfiles(),
        element: <Outlet />,
        children: [
          {
            path: urlPageUserProfiles(),
            element: (
              <HasRights allowedPermissions={[ Permissions.USER_PROFILES.USER_PROFILES_LIST.VIEW_PAGE ]}>
                <PageUserProfiles />
              </HasRights>
            ),
          },
          {
            path: urlPageUserProfileDetails(),
            element: <PageUserProfileDetails />,
            children: [
              {
                path: urlPageUserProfileDetails(),
                element: <PageUserProfileListDevices />,
              },
              {
                path: urlPageUserProfileDetailsDevice(),
                element: <Outlet />,
                children: [
                  {
                    path: urlPageUserProfileDetailsDevice(),
                    element: <PageUserProfileDevice />,
                  },
                  {
                    path: urlPageUserProfileDetailsDeviceAbTests(),
                    element: <PageUserProfileDetailsDeviceDetails />,
                  },
                  {
                    path: urlPageUserProfileDetailsDevicePurchases(),
                    element: <PageUserProfileDetailsDeviceDetails />,
                  },
                  {
                    path: urlPageUserProfileDetailsDevicePics(),
                    element: <PageUserProfileDetailsDeviceDetails />,
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        path: urlPageSegments(),
        element: <Outlet />,
        children: [
          {
            path: urlPageSegments(),
            element: (
              <HasRights allowedPermissions={[ Permissions.SEGMENTS.VIEW_ALL ]}>
                <PageSegments />
              </HasRights>
            ),
          },
          {
            path: urlPageSegmentDetails(),
            element: (
              <HasRights allowedPermissions={[ Permissions.SEGMENTS.VIEW_DETAILS ]}>
                <PageSegmentDetails />
              </HasRights>
            ),
          },
        ],
      },
      {
        path: urlPageSupport(),
        children: [
          {
            path: urlPageTickets(),
            element: (
              <HasRights allowedPermissions={[ Permissions.SUPPORT.TICKET.VIEW_PAGE ]}>
                <PageTickets />
              </HasRights>
            ),
          },
          {
            path: urlPageTicketDetails(),
            element: (
              <HasRights allowedPermissions={[ Permissions.SUPPORT.TICKET.VIEW_PAGE ]}>
                <PageTicketDetails />
              </HasRights>
            ),
          },
          {
            path: urlPageUserEvents(),
            element: (
              <HasRights allowedPermissions={[ Permissions.SUPPORT.USER_EVENTS.VIEW_PAGE ]}>
                <PageUserEvents />
              </HasRights>
            ),
          },
          {
            path: urlPageEventsSequence(),
            element: (
              <HasRights allowedPermissions={[ Permissions.SUPPORT.EVENTS_SEQUENCE.VIEW_PAGE ]}>
                <PageEventsSequence />
              </HasRights>
            ),
          },
        ],
      },
      {
        path: urlPageMonitoring(),
        children: [
          {
            path: urlPageGamesMonitor(),
            element: (
              <HasRights allowedPermissions={[ Permissions.MONITORING.GAMES_MONITOR.VIEW_PAGE ]}>
                <PageGamesMonitor />
              </HasRights>
            ),
          },
          {
            path: urlPageAppsMonitor(),
            element: (
              <HasRights allowedPermissions={[ Permissions.MONITORING.APPLICATION_MONITOR.VIEW_PAGE ]}>
                <PageApplicationMonitor />
              </HasRights>
            ),
          },
          {
            path: urlPageAdsMonitor(),
            element: (
              <HasRights allowedPermissions={[ Permissions.MONITORING.ADS_MONITOR.VIEW_PAGE ]}>
                <PageAdsMonitor />
              </HasRights>
            ),
          },
          {
            path: urlPageHintsMonitor(),
            element: (
              <HasRights allowedPermissions={[ Permissions.MONITORING.HINTS.VIEW_PAGE ]}>
                <PageHintsMonitor />
              </HasRights>
            ),
          },
          {
            path: urlPageContactUsMonitor(),
            element: (
              <HasRights allowedPermissions={[ Permissions.MONITORING.CONTACT_US.VIEW_PAGE ]}>
                <PageContactUsMonitor />
              </HasRights>
            ),
          },
        ],
      },
      {
        path: urlPageAbTests(),
        element: <Outlet />,
        children: [
          {
            path: urlPageAbTests(),
            element: (
              <HasRights allowedPermissions={[ Permissions.AB_TESTS.VIEW_PAGE ]}>
                <PageAbTests />
              </HasRights>
            ),
          },
          {
            path: urlPageCreateAbTest(),
            element: <PageCreateEditAbTest />,
          },
          {
            path: urlPageAbTestDetails(),
            element: <PageAbTestDetails />,
          },
          {
            path: urlPageEditAbTest(),
            element: <PageCreateEditAbTest />,
          },
        ],
      },
      {
        path: urlPageMixinsExperiments(),
        element: <Outlet />,
        children: [
          {
            path: urlPageMixinsExperiments(),
            element: (
              <HasRights
                allowedPermissions={[
                  Permissions.MIXINS_EXPERIMENTS.VIEW_ALL_LIST,
                  Permissions.MIXINS_EXPERIMENTS.VIEW_OWN_LIST,
                  Permissions.MIXINS_EXPERIMENTS.VIEW_FREE_LIST,
                ]}
              >
                <PageMixinsExperiments />
              </HasRights>
            ),
          },
          {
            path: urlPageCreateMixinsExperiment(),
            element: <PageCreateEditMixinsExperiment />,
          },
          {
            path: urlPageEditMixinsExperiment(),
            element: <PageCreateEditMixinsExperiment />,
          },
          {
            path: urlPageMixinsExperimentDetails(),
            element: <Outlet />,
            children: [
              {
                path: urlPageMixinsExperimentDetails(),
                element: <PageMixinsExperimentDetails />,
              },
              {
                path: urlPageCreateMixinsExperimentsMixin(),
                element: <PageCreateEditMixinsExperimentMixin />,
              },
              {
                path: urlPageEditMixinsExperimentMixin(),
                element: <PageCreateEditMixinsExperimentMixin />,
              },
              {
                path: urlPageCreateMixinsExperimentMixinTargeting(),
                element: <PageCreateEditMixinsExperimentTargeting />,
              },
              {
                path: urlPageEditMixinsExperimentTargeting(),
                element: <PageCreateEditMixinsExperimentTargeting />,
              },
            ],
          },
        ],
      },
      {
        path: urlPageConfigurations(),
        element: <Outlet />,
        children: [
          {
            path: urlPageConfigurations(),
            element: (
              <HasRights allowedPermissions={[ Permissions.CONFIGS.CONFIGURATIONS.VIEW_LIST ]}>
                <PageConfigurations />
              </HasRights>
            ),
          },
          {
            path: urlPageConfigurationsCreate(),
            element: <PageCreateEditConfiguration />,
          },
          {
            path: urlPageEditConfiguration(),
            element: <PageCreateEditConfiguration />,
          },
          {
            path: urlPageConfigurationDetails(),
            element: <Outlet />,
            children: [
              {
                path: urlPageConfigurationDetails(),
                element: <PageConfigurationDetails />,
              },
              {
                path: urlPageCreateConfigTargeting(),
                element: <PageCreateEditConfigTargeting />,
              },
              {
                path: urlPageEditConfigTargeting(),
                element: <PageCreateEditConfigTargeting />,
              },
              {
                path: urlPageCreateConfigMixin(),
                element: <PageCreateEditConfigMixin />,
              },
              {
                path: urlPageEditConfigMixin(),
                element: <PageCreateEditConfigMixin />,
              },
              {
                path: urlPageConfigMixinDetails(),
                element: <Outlet />,
                children: [
                  {
                    path: urlPageConfigMixinDetails(),
                    element: <PageConfigurationMixinDetails />,
                  },
                  {
                    path: urlPageCreateConfigMixinTargeting(),
                    element: <PageCreateEditConfigMixinTargeting />,
                  },
                  {
                    path: urlPageEditConfigMixinTargeting(),
                    element: <PageCreateEditConfigMixinTargeting />,
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        path: urlPageConfigVisualize(),
        element: <Outlet />,
        children: [
          {
            path: urlPageConfigVisualize(),
            element: (
              <HasRights allowedPermissions={[ Permissions.CONFIGS.CONFIG_VISUALIZE.VIEW_LIST_AND_DETAILS ]}>
                <PageConfigVisualize />
              </HasRights>
            ),
          },
          {
            path: urlPageConfigVisualizeCreate(),
            element: <PageCreateConfigVisualize />,
          },
          {
            path: urlPageConfigVisualizeDetails(),
            element: <PageCreateConfigVisualize />,
          },
          {
            path: urlPageConfigHashVisualize(),
            element: <PageConfigHashVisualize />,
          },
        ],
      },
      {
        path: urlPageMixinVisualize(),
        element: <Outlet />,
        children: [
          {
            path: urlPageMixinVisualize(),
            element: (
              <HasRights allowedPermissions={[ Permissions.CONFIGS.MIXIN_VISUALIZE.VIEW_MIXINS_LIST ]}>
                <PageMixinVisualize />
              </HasRights>
            ),
          },
          {
            path: urlPageConfigMixinVisualize(),
            element: (
              <HasRights allowedPermissions={[ Permissions.CONFIGS.CONFIGURATIONS.TARGETING.CREATE ]}>
                <PageMixinVisualize />
              </HasRights>
            ),
          },
        ],
      },
      {
        path: urlPageConsentProvider(),
        element: <Outlet />,
        children: [
          {
            path: urlPageConsentProvider(),
            element: <PageConsentProvider />,
          },
          {
            path: urlPageProjectUserDetails(),
            element: <PageProjectUserDetails />,
          },
        ],
      },
      {
        path: urlPageStructures(),
        element: <Outlet />,
        children: [
          {
            path: urlPageStructures(),
            element: (
              <HasRights allowedPermissions={[ Permissions.CONFIGS.STRUCTURES.VIEW_LIST ]}>
                <PageStructures />
              </HasRights>
            ),
          },
          {
            path: urlPageStructureDetails(),
            element: <PageStructureDetails />,
          },
        ],
      },
      {
        path: urlPageSettings(),
        element: <Outlet />,
        children: [
          {
            path: urlPageSettingsUsers(),
            element: <Outlet />,
            children: [
              {
                path: urlPageSettingsUsers(),
                element: (
                  <HasRights allowedPermissions={[ Permissions.ADMINISTRATION.USERS.USERS_LIST.VIEW_PAGE ]}>
                    <PageUsers />
                  </HasRights>
                ),
              },
              {
                path: urlPageSettingsUserDetails(),
                element: <PageUserDetails />,
              },
            ],
          },
          {
            path: urlPageSettingsLogs(),
            element: <Outlet />,
            children: [
              {
                path: urlPageSettingsLogs(),
                element: (
                  <HasRights allowedPermissions={[ Permissions.ADMINISTRATION.LOGS.VIEW_PAGE ]}>
                    <PageLogs />
                  </HasRights>
                ),
              },
              {
                path: urlPageSettingsLogDetails(),
                element: <PageLogDetails />,
              },
            ],
          },
          {
            path: urlPageSettingsPermissions(),
            element: (
              <HasRights allowedPermissions={[ Permissions.ADMINISTRATION.PERMISSIONS.PERMISSIONS_LIST.VIEW_PAGE ]}>
                <PagePermissions />
              </HasRights>
            ),
          },
          {
            path: urlPageSettingsRoles(),
            element: <Outlet />,
            children: [
              {
                path: urlPageSettingsRoles(),
                element: (
                  <HasRights allowedPermissions={[ Permissions.ADMINISTRATION.ROLES.ROLES_LIST.VIEW_PAGE ]}>
                    <PageRoles />
                  </HasRights>
                ),
              },
              {
                path: urlPageSettingsRoleDetails(),
                element: <PageEditRole />,
              },
            ],
          },
          {
            path: urlPageSettingsProjects(),
            element: <Outlet />,
            children: [
              {
                path: urlPageSettingsProjects(),
                element: (
                  <HasRights allowedPermissions={[ Permissions.ADMINISTRATION.PROJECTS.VIEW_LIST ]}>
                    <PageProjects />
                  </HasRights>
                ),
              },
              {
                path: urlPageSettingsCreateProject(),
                element: <PageCreateEditProject />,
              },
              {
                path: urlPageSettingsEditProject(),
                element: <PageCreateEditProject />,
              },
            ],
          },
        ],
      },
      {
        path: urlPageAdministration(),
        element: <Outlet />,
        children: [
          {
            path: urlPageUsers(),
            element: <Outlet />,
            children: [
              {
                path: urlPageUsers(),
                element: (
                  <HasRights allowedPermissions={[ Permissions.ADMINISTRATION.USERS.USERS_LIST.VIEW_PAGE ]}>
                    <PageUsers />
                  </HasRights>
                ),
              },
              {
                path: urlPageUserDetails(),
                element: <PageUserDetails />,
              },
            ],
          },
          {
            path: urlPageLogs(),
            element: <Outlet />,
            children: [
              {
                path: urlPageLogs(),
                element: (
                  <HasRights allowedPermissions={[ Permissions.ADMINISTRATION.LOGS.VIEW_PAGE ]}>
                    <PageLogs />
                  </HasRights>
                ),
              },
              {
                path: urlPageLogDetails(),
                element: <PageLogDetails />,
              },
            ],
          },
          {
            path: urlPagePermissions(),
            element: (
              <HasRights allowedPermissions={[ Permissions.ADMINISTRATION.PERMISSIONS.PERMISSIONS_LIST.VIEW_PAGE ]}>
                <PagePermissions />
              </HasRights>
            ),
          },
          {
            path: urlPageRoles(),
            element: <Outlet />,
            children: [
              {
                path: urlPageRoles(),
                element: (
                  <HasRights allowedPermissions={[ Permissions.ADMINISTRATION.ROLES.ROLES_LIST.VIEW_PAGE ]}>
                    <PageRoles />
                  </HasRights>
                ),
              },
              {
                path: urlPageRoleDetails(),
                element: <PageEditRole />,
              },
            ],
          },
          {
            path: urlPageSpaces(),
            element: <Outlet />,
            children: [
              {
                path: urlPageSpaces(),
                element: (
                  <HasRights allowedPermissions={[ Permissions.ADMINISTRATION.SPACES.VIEW_LIST ]}>
                    <PageSpaces />
                  </HasRights>
                ),
              },
              {
                path: urlPageSpaceProjects(),
                element: <Outlet />,
                children: [
                  {
                    path: urlPageSpaceProjects(),
                    element: (
                      <HasRights allowedPermissions={[ Permissions.ADMINISTRATION.PROJECTS.VIEW_LIST ]}>
                        <PageProjects />
                      </HasRights>
                    ),
                  },
                  {
                    path: urlPageSpaceCreateProject(),
                    element: <PageCreateEditProject />,
                  },
                  {
                    path: urlPageSpaceEditProject(),
                    element: <PageCreateEditProject />,
                  },
                ],
              },
            ],
          },
          {
            path: urlPageProjects(),
            element: <Outlet />,
            children: [
              {
                path: urlPageProjects(),
                element: (
                  <HasRights allowedPermissions={[ Permissions.ADMINISTRATION.PROJECTS.VIEW_LIST ]}>
                    <PageProjects />
                  </HasRights>
                ),
              },
              {
                path: urlPageCreateProject(),
                element: <PageCreateEditProject />,
              },
              {
                path: urlPageEditProject(),
                element: <PageCreateEditProject />,
              },
            ],
          },
        ],
      },
    ],
  },
  { path: '*', element: <Error404 /> },
];

export default routes;
