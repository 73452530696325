import React from 'react';
import { Button, Card, Table, Tag, Tooltip } from 'antd';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { EyeOutlined } from '@ant-design/icons';
import urlPageConfigurationDetails from '../../../../urls/urlPageConfigurationDetails';
import urlPageConfigMixinDetails from '../../../../urls/urlPageConfigMixinDetails';
import urlPageAbTestDetails from '../../../../urls/urlPageAbTestDetails';
import urlPageMixinsExperimentDetails from '../../../../urls/urlPageMixinsExperimentDetails';



const getStatusColor = (status) => {
  switch (status) {
    case 'enabled':
    case 'active':
      return 'success';
    case 'draft':
    case 'paused':
      return 'warning';
    case 'created':
      return 'processing';
    case 'on_checking':
      return 'geekblue';
    case 'review':
      return 'orange';
    case 'free':
      return 'cyan';
    case 'approved':
      return 'green';
    case 'released':
      return 'blue';
    case 'archived':
      return 'default';
    default:
      return 'default';
  }
};

const makeTargetLink = (record) => {
  switch (record.type) {
    case 'config':
      return urlPageConfigurationDetails({ configurationId: record.model.id });
    case 'mixin':
      return urlPageConfigMixinDetails({
        configurationId: record.model.config_id,
        configurationName: record.model.config_name,
        mixinId: record.model.id,
      });
    case 'ab_test':
      return urlPageAbTestDetails({ abTestName: record.model.name });
    case 'experiment':
      return urlPageMixinsExperimentDetails({ testId: record.model.id, testName: record.model.name });
    default:
      return '';
  }
};

const columns = [
  {
    title: 'Entity ID',
    dataIndex: [ 'model', 'id' ],
    key: 'id',
  },
  {
    title: 'Name',
    dataIndex: [ 'model', 'name' ],
    key: 'name',
  },
  {
    title: 'Type',
    dataIndex: 'type',
    key: 'type',
    render: (type) => <Tag>{type}</Tag>,
  },
  {
    title: 'Status',
    dataIndex: [ 'model', 'status' ],
    key: 'status',
    render: (status) => (
      <Tag color={getStatusColor(status)}>
        {status}
      </Tag>
    ),
  },
  {
    title: 'Actions',
    key: 'actions',
    render: (record) => (
      <Tooltip placement="top" title='Details'>
        <Button size="small" type='primary' className='btn-with-side-margin'>
          <Link to={makeTargetLink(record)}>
            <EyeOutlined />
          </Link>
        </Button>
      </Tooltip>
    ),
  },
];

const SegmentTargetsCard = ({ segment, loading }) => {
  const dependencies = segment?.dependencies?.data || [];

  return (
    <Card title="Targets" loading={loading} style={{ marginTop: 16 }}>
      <Table
        columns={columns}
        dataSource={dependencies}
        rowKey={(record) => `${record.type}-${record.id}`}
        pagination={false}
      />
    </Card>
  );
};

SegmentTargetsCard.propTypes = {
  segment: PropTypes.shape({
    dependencies: PropTypes.shape({
      data: PropTypes.arrayOf(PropTypes.shape({
        type: PropTypes.string,
        id: PropTypes.number,
        model: PropTypes.shape({
          id: PropTypes.number,
          name: PropTypes.string,
          status: PropTypes.string,
          config_name: PropTypes.string,
        }),
      })),
    }),
  }),
  loading: PropTypes.bool.isRequired,
};

export default SegmentTargetsCard;
