import Api from '../../../services/Api/Api';
import { setRequestParams } from '../../../lib/setRequestParams';



class SegmentsService {
  constructor (Api) {
    this.api = Api;
  }

  getList (params) {
    return this.api.get(`segments${setRequestParams(params)}`);
  }

  getDetails (params) {
    const { project, segmentId } = params;

    return this.api.get(`segments/${segmentId}${setRequestParams({ project })}`);
  }

  checkUser (params) {
    const { segmentId, uuid, project } = params;

    return this.api.get(`segments/${segmentId}/users/${uuid}${setRequestParams({ project })}`);
  }

  deleteSegment (params) {
    const { segmentId, project } = params;

    return this.api.post(`segments/${segmentId}/delete${setRequestParams({ project })}`);
  }
}

const segmentsService = new SegmentsService(Api);

export default segmentsService;
