import React from 'react';
import moment from 'moment';
import { Badge, Button, Popconfirm, Row, Tooltip } from 'antd';
import { DeleteOutlined, EyeOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import capitalize from 'lodash/capitalize';
import urlPageAbTestDetails from '../../../../urls/urlPageAbTestDetails';
import { getDefaultTableSorting } from '../../../../lib/getDefaultTableSorting';
import { hasRights, Permissions } from '../../../../const/permissions';
import { EMPTY_VALUE_PLACEHOLDER, renderDateField } from '../../../../const/system';
import { PLATFORM_OPTIONS } from '../../forms/FormCreateEditAbTest/FormCreateEditAbTest.const';



export const AB_TEST_STATUS_MAP = {
  DRAFT: 'draft',
  ACTIVE: 'active',
  PAUSED: 'paused',
  ARCHIVED: 'archived',
};

export const AB_TEST_STATUS_COLOR = (status) => {
  switch (status) {
    case AB_TEST_STATUS_MAP.DRAFT: {
      return 'geekblue';
    }
    case AB_TEST_STATUS_MAP.ACTIVE: {
      return 'green';
    }
    case AB_TEST_STATUS_MAP.ARCHIVED: {
      return 'red';
    }
    case AB_TEST_STATUS_MAP.PAUSED: {
      return 'orange';
    }
    default: {
      return 'yellow';
    }
  }
};

export const AB_TEST_PUBLISH_STATUS_COLOR = (data) => {
  const updatedAt = moment(data.updated_at);
  const publishedAt = moment(data.published_at);
  const timeDifference = updatedAt.diff(publishedAt);
  const allowedDiffMilliSeconds = 2000;

  if (Math.abs(timeDifference) <= allowedDiffMilliSeconds) {
    return 'green';
  }
  if (Math.abs(timeDifference) > allowedDiffMilliSeconds) {
    return 'yellow';
  }
  return 'gray';
};

export const getAbTestTableColumns = (handleDeleteAbTest) => [
  {
    title: 'ID',
    align: 'center',
    width: '80px',
    fixed: 'left',
    sorter: true,
    defaultSortOrder: getDefaultTableSorting('id'),
    render: (value) => <Badge color={AB_TEST_PUBLISH_STATUS_COLOR(value)} text={value.id} />,
  },
  {
    title: 'Name',
    width: '230px',
    fixed: 'left',
    align: 'center',
    render: (item) => {
      return (
        <>
          {hasRights([ Permissions.AB_TESTS.VIEW_DETAILS ]) && !isEmpty(item.name) ? (
            <Link to={urlPageAbTestDetails({ abTestName: item.name })}>
              {item.name}
            </Link>
          ) : (<div>{item.name || EMPTY_VALUE_PLACEHOLDER}</div>)}
        </>
      );
    },
  },
  {
    title: 'Status',
    dataIndex: 'status',
    fixed: 'left',
    width: '130px',
    align: 'center',
    render: (value) => <Badge color={AB_TEST_STATUS_COLOR(value)} text={capitalize(value)} />,
  },
  {
    title: 'Priority',
    fixed: 'left',
    width: '130px',
    align: 'center',
    dataIndex: 'priority',
    sorter: true,
    render: (value, item) => {
      return (<div>{item?.data?.priority || EMPTY_VALUE_PLACEHOLDER}</div>);
    },
  },
  {
    title: 'Platforms',
    fixed: 'left',
    width: '130px',
    align: 'center',
    render: (item) => {
      const platforms = item?.data?.conditions
        .reduce((uniquePlatforms, condition, index, conditions) => {
          const hasInclude = conditions.some((cond) => cond.condition_type === 'include');

          if (hasInclude && condition.condition_type === 'include') {
            if (isEmpty(condition.platforms)) {
              PLATFORM_OPTIONS.forEach((option) => uniquePlatforms.add(option.value));
            } else {
              condition.platforms.forEach((platform) => uniquePlatforms.add(platform));
            }
          } else if (!hasInclude && condition.condition_type === 'exclude') {
            PLATFORM_OPTIONS.forEach((option) => {
              if (!condition.platforms.includes(option.value)) {
                uniquePlatforms.add(option.value);
              }
            });
          }
          return uniquePlatforms;
        }, new Set());

      return (
        <div>
          {platforms.size > 0
            ? [ ...platforms ].map((platform) => {
              const match = PLATFORM_OPTIONS.find((option) => option.value === platform);

              return (<div key={item.data.id}>{match ? match.label : platform}</div>);
            })
            : EMPTY_VALUE_PLACEHOLDER}
        </div>
      );
    },
  },
  {
    title: 'Created at',
    dataIndex: 'created_at',
    width: '200px',
    align: 'center',
    sorter: true,
    defaultSortOrder: getDefaultTableSorting('created_at'),
    render: (value) => renderDateField(value),
  },
  {
    title: 'Created by',
    width: '230px',
    align: 'center',
    render: (item) => item?.author?.name ?? EMPTY_VALUE_PLACEHOLDER,
  },
  {
    title: 'First published at',
    dataIndex: 'first_published_at',
    width: '200px',
    align: 'center',
    sorter: true,
    defaultSortOrder: getDefaultTableSorting('first_published_at'),
    render: (value) => renderDateField(value),
  },
  {
    title: 'First publisher',
    width: '230px',
    align: 'center',
    render: (item) => item?.first_publisher?.name ?? EMPTY_VALUE_PLACEHOLDER,
  },
  {
    title: 'Published at',
    dataIndex: 'published_at',
    width: '200px',
    align: 'center',
    sorter: true,
    defaultSortOrder: getDefaultTableSorting('published_at'),
    render: (value) => renderDateField(value),
  },
  {
    title: 'Publisher',
    width: '230px',
    align: 'center',
    render: (item) => item?.publisher?.name ?? EMPTY_VALUE_PLACEHOLDER,
  },
  ...(hasRights([ Permissions.AB_TESTS.VIEW_DETAILS, Permissions.AB_TESTS.DELETE ]) ? [
    {
      title: 'Action',
      key: 'action',
      fixed: 'right',
      align: 'center',
      width: '150px',
      render: (item) => {
        return (
          <Row className="da-h-100" align="middle" justify="center">
            {hasRights([ Permissions.AB_TESTS.DELETE ]) && !item.is_published && (
              <Popconfirm title="Sure to delete?" onConfirm={() => handleDeleteAbTest(item.id)}>
                <Tooltip placement="top" title='Delete ab test'>
                  <Button
                    size="small"
                    type='primary'
                    danger
                    className='btn-with-side-margin'
                  >
                    <DeleteOutlined />
                  </Button>
                </Tooltip>
              </Popconfirm>
            )}
            {hasRights([ Permissions.AB_TESTS.VIEW_DETAILS ]) && (
              <Tooltip placement="top" title='Details'>
                <Button size="small" type='primary' className='btn-with-side-margin'>
                  <Link to={urlPageAbTestDetails({ abTestName: item.name })}>
                    <EyeOutlined />
                  </Link>
                </Button>
              </Tooltip>
            )}
          </Row>
        );
      },
    } ] : []
  ),
];
