import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col, Breadcrumb, message, Popconfirm, Tooltip, Spin } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { useApp } from '../../../../app/context/AppContext';
import { getSegmentDetails, selectSegmentDetails, selectSegmentDetailsLoading, deleteSegment } from '../../feature/segmentsSlice';
import { hasRights, Permissions } from '../../../../const/permissions';
import { mkRedirectToDashboardPath } from '../../../../lib/mkRedirectToDashboard';
import urlPageSegments from '../../../../urls/urlPageSegments';
import { BTN_SIDE_MARGIN } from '../../../../const/system';
import HasRights from '../../../../components/HasRights';
import SegmentDetailsCard from './SegmentDetailsCard';
import SegmentUserCheckCard from './SegmentUserCheckCard';
import SegmentTargetsCard from './SegmentTargetsCard';



const PageSegmentDetails = () => {
  const dispatch = useDispatch();
  const { projectSettings } = useApp();
  const { segName } = useParams();
  const segment = useSelector(selectSegmentDetails);
  const loading = useSelector(selectSegmentDetailsLoading);
  const [ isDeleting, setIsDeleting ] = useState(false);

  useEffect(() => {
    if (projectSettings?.projectKey && segName) {
      dispatch(getSegmentDetails({
        project: projectSettings.projectKey,
        segmentId: segName,
      }));
    }
  }, [ dispatch, projectSettings, segName ]);

  const handleDelete = async () => {
    setIsDeleting(true);
    try {
      const result = await dispatch(deleteSegment({
        project: projectSettings.projectKey,
        segmentId: segName,
      })).unwrap();

      if (result.data.status === 'deleting') {
        message.success('Segment deletion initiated');

        setIsDeleting(false);
        dispatch(getSegmentDetails({
          project: projectSettings.projectKey,
          segmentId: segName,
        }));
      } else {
        message.error('Failed to delete segment');
        setIsDeleting(false);
      }
    } catch (error) {
      message.error(`Failed to delete segment: ${error}`);
      setIsDeleting(false);
    }
  };

  const isDisabled = loading || isDeleting || segment?.status === 'deleting';

  return (
    <HasRights allowedPermissions={[ Permissions.SEGMENTS.VIEW_DETAILS ]}>
      <Spin spinning={isDeleting} tip="Deleting segment...">
        <Col span={24}>
          <Row justify="space-between">
            <Breadcrumb separator=">">
              <Breadcrumb.Item>
                <Link to={mkRedirectToDashboardPath(projectSettings)}>
                  Home
                </Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link to={urlPageSegments()}>
                  Segments
                </Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>{segName}</Breadcrumb.Item>
            </Breadcrumb>

            <Col>
              {hasRights([ Permissions.SEGMENTS.DELETE ]) && (
                <Popconfirm
                  title="Are you sure you want to delete this segment?"
                  onConfirm={handleDelete}
                  disabled={isDisabled}
                >
                  <Tooltip placement="top" title='Delete segment'>
                    <Button
                      style={BTN_SIDE_MARGIN}
                      disabled={isDisabled}
                      size='middle'
                      type="secondary"
                      danger
                      icon={<DeleteOutlined style={{ marginRight: 5 }} />}
                    >
                      Delete Segment
                    </Button>
                  </Tooltip>
                </Popconfirm>
              )}
            </Col>
          </Row>
        </Col>

        <div className="da-mt-32">
          <Row gutter={[ 16, 16 ]}>
            <Col xs={24} md={24} xl={8}>
              <SegmentDetailsCard segment={segment} loading={loading} />
            </Col>

            <Col xs={24} md={24} xl={16}>
              <SegmentUserCheckCard segment={segment} loading={loading} />
              <SegmentTargetsCard segment={segment} loading={loading} />
            </Col>
          </Row>
        </div>
      </Spin>
    </HasRights>
  );
};

export default PageSegmentDetails;
