import React from 'react';
import { Space, Button, Popconfirm, Tooltip, Tag } from 'antd';
import moment from 'moment';
import { DeleteOutlined, EyeOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { hasRights, Permissions } from '../../../../const/permissions';
import urlPageSegmentDetails from '../../../../urls/urlPageSegmentDetails';
import { renderDateField } from '../../../../const/system';



const statusColors = {
  enabled: 'green',
  obsolete: 'black',
  deleting: 'orange',
  removed: 'red',
};

export const getColumns = ({ handleDelete }) => [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    width: '150px',
    sorter: true,
  },
  {
    title: 'User Count',
    dataIndex: 'users_count',
    key: 'users_count',
    width: '100px',
    sorter: true,
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    width: '100px',
    sorter: true,
    render: (status) => (
      <Tag color={statusColors[status]}>{status}</Tag>
    ),
  },
  {
    title: 'Last updated',
    dataIndex: 'updated_at',
    key: 'updated_at',
    width: '150px',
    sorter: true,
    render: (date) => renderDateField(moment(date)),
  },
  {
    title: 'Created At',
    dataIndex: 'created_at',
    key: 'created_at',
    width: '150px',
    sorter: true,
    render: (date) => renderDateField(moment(date)),
  },
  {
    title: 'Actions',
    key: 'actions',
    width: '50px',
    render: (_, record) => (
      <Space>
        {hasRights([ Permissions.SEGMENTS.VIEW_DETAILS ]) && (
          <Tooltip placement="top" title='Details'>
            <Button size="small" type='primary' className='btn-with-side-margin' disabled={record.status === 'removed'}>
              <Link to={urlPageSegmentDetails({ segName: record.name })}>
                <EyeOutlined />
              </Link>
            </Button>
          </Tooltip>
        )}
        {hasRights([ Permissions.SEGMENTS.DELETE ]) && (
          <Popconfirm
            title="Are you sure you want to delete this segment?"
            onConfirm={() => handleDelete(record.name)}
            disabled={record.status === 'deleting' || record.status === 'removed'}
          >
            <Tooltip placement="top" title='Delete segment'>
              <Button
                size="small"
                type='primary'
                danger
                className='btn-with-side-margin'
                disabled={record.status === 'deleting' || record.status === 'removed'}
              >
                <DeleteOutlined />
              </Button>
            </Tooltip>
          </Popconfirm>
        )}
      </Space>
    ),
  },
];
