import { Breadcrumb, Spin } from 'antd';
import { Link, useNavigate, useLocation, useParams } from 'react-router-dom';
import React, { useEffect } from 'react';
import isEmpty from 'lodash/isEmpty';
import { useDispatch, useSelector } from 'react-redux';
import urlPageConfigurations from '../../../../urls/urlPageConfigurations';
import urlPageConfigurationDetails from '../../../../urls/urlPageConfigurationDetails';
import urlPageConfigMixinDetails from '../../../../urls/urlPageConfigMixinDetails';
import FormCreateEditConfigTargeting from '../../forms/FormCreateEditConfigTargeting';
import HasRights from '../../../../components/HasRights';
import { Permissions } from '../../../../const/permissions';
import { getPreparedFormData } from './PageCreateEditConfigMixinTargeting.const';
import { useApp } from '../../../../app/context/AppContext';
import { isFulfilledRequestStatus } from '../../../../lib/isRequestSuccess';
import { mkRedirectToDashboardPath } from '../../../../lib/mkRedirectToDashboard';
import {
  predefinedFormCreateInitialValues,
  preparedToRequestFormData,
} from '../PageCreateEditConfigTargeting/PageCreateEditConfigTargeting.const';
import {
  getAbTestsListData,
  resetAbTestsListData,
  selectFormConfigTargetingAbTests,
  selectFormConfigTargetingAbTestsLoading,
} from '../../feature/configurationDetailsSlice';

import {
  getSegmentsList,
  resetSegmentsList,
  selectSegmentsList,
  selectSegmentsLoading,
} from '../../../Segments/feature/segmentsSlice';
import {
  createConfigMixinTargeting,
  getConfigMixinDetails,
  getConfigMixinTargetDetails,
  resetConfigMixinTargetDetailsData,
  selectConfigMixinDetails,
  selectConfigMixinDetailsLoading,
  selectConfigMixinTargetDetails,
  selectConfigMixinTargetDetailsLoading,
  selectCreateConfigMixinTargetingErrors,
  selectCreateConfigMixinTargetingLoading,
  selectUpdateConfigMixinTargetingErrors,
  selectUpdateConfigMixinTargetingLoading,
  updateConfigMixinTargeting,
} from '../../feature/configurationMixinDetailsSlice';



const PageCreateEditConfigMixinTargeting = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { projectSettings, userName } = useApp();
  const navigate = useNavigate();
  const { pathname } = location;
  const { configurationId, configurationName, mixinId, targetingId } = useParams();
  const isCreateConfigMixinTargeting = pathname.endsWith('create');

  const isLoadingMixinDetails = useSelector(selectConfigMixinDetailsLoading);

  const isLoadingCreateMixinTarget = useSelector(selectCreateConfigMixinTargetingLoading);
  const createMixinTargetErrors = useSelector(selectCreateConfigMixinTargetingErrors);
  const isLoadingUpdateMixinTarget = useSelector(selectUpdateConfigMixinTargetingLoading);
  const updateMixinTargetErrors = useSelector(selectUpdateConfigMixinTargetingErrors);
  const configMixinTargetingDetails = useSelector(selectConfigMixinTargetDetails);
  const configMixinDetails = useSelector(selectConfigMixinDetails);
  const isLoadingConfigMixinTargeting = useSelector(selectConfigMixinTargetDetailsLoading);

  const segmentList = useSelector(selectSegmentsList);
  const isLoadingSegmentList = useSelector(selectSegmentsLoading);
  const abTestsList = useSelector(selectFormConfigTargetingAbTests);
  const isLoadingAbTestsList = useSelector(selectFormConfigTargetingAbTestsLoading);

  useEffect(() => {
    dispatch(getAbTestsListData());
    dispatch(getSegmentsList({ limit: -1 }));
    dispatch(getConfigMixinDetails(mixinId));
    if (!isEmpty(targetingId)) {
      dispatch(getConfigMixinTargetDetails(mixinId));
    }

    return () => {
      dispatch(resetConfigMixinTargetDetailsData());
      dispatch(resetAbTestsListData());
      dispatch(resetSegmentsList());
    };
  }, []);


  const handleRedirectToConfigMixinDetails = (response) => {
    if (isFulfilledRequestStatus(response)) {
      navigate(urlPageConfigMixinDetails({ configurationId, configurationName, mixinId }));
    }
  };

  const handleSubmitFormData = async (values) => {
    const data = preparedToRequestFormData(values);

    if (isCreateConfigMixinTargeting) {
      await dispatch(createConfigMixinTargeting({ mixinId, data })).then((response) => {
        handleRedirectToConfigMixinDetails(response);
      });
    } else {
      await dispatch(updateConfigMixinTargeting({ mixinId, targetingId, data })).then((response) => {
        handleRedirectToConfigMixinDetails(response);
      });
    }
  };

  const initialValues = !isCreateConfigMixinTargeting ?
    getPreparedFormData(configMixinTargetingDetails, configurationName) :
    predefinedFormCreateInitialValues({ configName: configurationName, authorName: userName, configMixinDetails });

  return (
    <HasRights allowedPermissions={[ Permissions.CONFIGS.CONFIGURATIONS.MIXINS.TARGETING.CREATE, Permissions.CONFIGS.CONFIGURATIONS.MIXINS.TARGETING.UPDATE ]}>
      <Breadcrumb separator=">">
        <Breadcrumb.Item>
          <Link to={mkRedirectToDashboardPath(projectSettings)}>
            Home
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link to={urlPageConfigurations()}>
            Configurations list
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link to={urlPageConfigurationDetails({ configurationId })}>
            Configuration details
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link to={urlPageConfigMixinDetails({ configurationId, configurationName, mixinId })}>
            Mixin details
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          {`${isCreateConfigMixinTargeting ? 'Create' : 'Edit'} targeting`}
        </Breadcrumb.Item>
      </Breadcrumb>

      <Spin
        spinning={
          isLoadingCreateMixinTarget ||
          isLoadingUpdateMixinTarget ||
          isLoadingConfigMixinTargeting ||
          isLoadingAbTestsList ||
          isLoadingMixinDetails
        }
        tip="Loading form. Please wait..."
      >
        <FormCreateEditConfigTargeting
          isMixinTargeting
          initialValues={initialValues}
          isEditForm={!isCreateConfigMixinTargeting}
          formErrors={createMixinTargetErrors || updateMixinTargetErrors}
          abTestsList={abTestsList}
          segmentList={segmentList}
          isLoadingAbTestsList={isLoadingAbTestsList}
          isLoadingSegmentList={isLoadingSegmentList}
          onCancel={() => {
            navigate(-1);
          }}
          isSubmitting={isLoadingCreateMixinTarget || isLoadingUpdateMixinTarget || isLoadingConfigMixinTargeting}
          onSubmit={handleSubmitFormData}
        />
      </Spin>
    </HasRights>
  );
};

export default PageCreateEditConfigMixinTargeting;
