import React from 'react';
import { Form, FormItem, Input, Select, SubmitButton, DatePicker } from 'formik-antd';
import { FieldArray, Formik, FastField } from 'formik';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Alert, Button, Card, Col, Divider, Empty, Popconfirm, Row, Spin } from 'antd';
import moment from 'moment';
import capitalize from 'lodash/capitalize';
import isEmpty from 'lodash/isEmpty';
import isNumber from 'lodash/isNumber';
import cloneDeep from 'lodash/cloneDeep';
import { RiAddFill } from 'react-icons/ri';
import Yup from '../../../../vendor/yup';
import { mkSelectFilterOption } from '../../../../lib/mkSelectFilterOption';
import { getObjectDeepClean } from '../../../../lib/getObjectDeepClean';
import { isSmallerThan } from '../../../../lib/isSmallerThan';
import { COUNTRIES_LIST, SEMVER_REGEXP } from '../../../../const/system';
import {
  AB_TESTS_LANGUAGES_OPTIONS,
  DEVICE_MANUFACTURES_OPTIONS,
  DEVICE_TYPE_OPTIONS,
  PLATFORM_OPTIONS,
  USERS_TYPES_OPTIONS,
  getTotalPercentage,
  checkGroupsUniqueNames, LAW_TYPES_OPTIONS, LAT_TYPES_OPTIONS, AB_TEST_AFFILIATION_OPTIONS,
} from './FormCreateEditAbTest.const';
import { AB_TEST_STATUS_MAP } from '../../pages/PageAbTests/PageAbTests.const';
import { AB_TEST_INITIAL_CONDITION_FIELD_VALUES } from '../../pages/PageCreateEditAbTest/PageCreateEditAbTest.const';
import './index.css';



const { RangePicker } = DatePicker;
const { Option } = Select;

const FormCreateEditAbTestButtonsRow = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 30px;
`;

const FormCreateEditAbTestButtonWr = styled.div`
  width: 130px;
  padding: 0 10px 12px 10px;
  margin-top: 20px;
`;

export const TFormDateRangeDivider = styled.div`
  display: flex;
  justify-content: center;
  line-height: 40px;
  color: #dfe6e9;
  font-size: 16px;
`;



const validationSchema = Yup.lazy((values) => {
  const semverField = Yup.string().nullable().matches(SEMVER_REGEXP, 'The value does not match the semver regular expression').max(100, 'Max 100 symbols required');

  return (
    Yup.object().shape({
      name: Yup.string()
        .matches(/^[a-zA-Z\d-_]*$/, 'Only latin literatures, numbers, _ and - is allowed')
        .min(5, 'Min 5 symbols required')
        .max(100, 'Max 100 symbols required')
        .required('Name is required'),
      status: Yup.string().required('Required to select status'),
      affiliation: Yup.string().required('Required to select affiliation'),
      created_at: Yup.string().nullable(),
      started_at: Yup.string().nullable(),
      priority: Yup.number().typeError('Specify a number').min(1, 'Should be positive number').max(100, 'Should be less or equal 100').required('Required field'),
      percent: Yup.number().typeError('Specify a percent').min(1, 'Should be positive number').max(100, 'Should be less or equal 100').required('Required field'),
      link: Yup.string()
        .min(5, 'Min 5 symbols required')
        .max(255, 'Max 255 symbols required')
        .matches(/https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/, 'Invalid link, https prefix is required')
        .nullable(),
      description: Yup.string()
        .min(5, 'Min 5 symbols required')
        .max(255, 'Max 255 symbols required')
        .required('Description field is required'),
      segments: Yup.array().of(
        Yup.string().min(3, 'Min 3 symbols required').nullable(),
      ).nullable(),
      groups: Yup.array().of(
        Yup.object().shape({
          name: Yup.string()
            .min(1, 'Minimum 1 symbol required')
            .max(50, 'Maximum 50 symbols required')
            .matches(/^[a-zA-Z\d-_]*$/, 'Only latin literatures, numbers, _ and - is allowed')
            .required('Required field')
            .test(
              'is-uniq-names',
              'Group name should be unique',
              () => checkGroupsUniqueNames(values.groups),
            ),
          percent: Yup.number().typeError('Specify a number').min(1, 'Min value 1').max(100, 'Max value 100').required('Required field')
            .test(
              'is-valid-percents',
              'Total groups percentage should be 100%',
              () => getTotalPercentage(values),
            ),
        }),
      ),
      conditions: Yup.array().of(
        Yup.object().shape({
          condition_type: Yup.string().nullable().required('Required to select condition type'),
          users: Yup.object().shape({
            types: Yup.array().nullable(),
            list: Yup.array().of(Yup.string().min(3, 'Min 3 symbols required').nullable()),
          }),
          ram_min: Yup.number().integer()
            .typeError('Specify integer value')
            .min(0, 'Should be equal or more than 0')
            .max(65000, 'Should be less or equal 65000')
            .nullable().test(
              'check-ram-min',
              'Min value should be less than or equal to the max',
              (value, context) => {
                const { index } = context.options;
                const maxValue = values.conditions[index]?.ram_max;

                if (value && maxValue) {
                  return Number(value) <= Number(maxValue);
                }
                return true;
              },
            ),
          ram_max: Yup.number().integer()
            .typeError('Specify integer value')
            .min(0, 'Should be equal or more than 0')
            .max(65000, 'Should be less or equal 65000')
            .nullable().test(
              'check-ram-max',
              'Max value should be greater than or equal to the min',
              (value, context) => {
                const { index } = context.options;
                const minValue = values.conditions[index]?.ram_min;

                if (value && minValue) {
                  return Number(minValue) <= Number(value);
                }
                return true;
              },
            ),
          input_ram_list: Yup.number().integer().typeError('Specify integer value').min(0, 'Should be equal or more than 0').max(65000, 'Should be less or equal 65000').nullable(),
          device_performance_min: Yup.number().integer()
            .typeError('Specify integer value')
            .min(0, 'Should be equal or more than 0')
            .max(65000, 'Should be less or equal 65000')
            .nullable().test(
              'check-device-performance-min',
              'Min value should be less than or equal to the max',
              (value, context) => {
                const { index } = context.options;
                const maxValue = values.conditions[index]?.device_performance_max;

                if (value && maxValue) {
                  return Number(value) <= Number(maxValue);
                }
                return true;
              },
            ),
          device_performance_max: Yup.number().integer()
            .typeError('Specify integer value')
            .min(0, 'Should be equal or more than 0')
            .max(65000, 'Should be less or equal 65000')
            .nullable().test(
              'check-device-performance-max',
              'Max value should be greater than or equal to the min',
              (value, context) => {
                const { index } = context.options;
                const minValue = values.conditions[index]?.device_performance_min;

                if (value && minValue) {
                  return Number(minValue) <= Number(value);
                }

                return true;
              },
            ),
          input_device_performance_list: Yup.number().integer().typeError('Specify integer value').min(0, 'Should be equal or more than 0').max(65000, 'Should be less or equal 65000').nullable(),
          input_os_versions_list: semverField,
          input_app_versions_list: semverField,
          os_versions_min: Yup.string()
            .matches(SEMVER_REGEXP, 'The value does not match the semver regular expression')
            .nullable().test(
              'check-os-min',
              'Min value should be less than or equal to the max',
              (value, context) => {
                const { index } = context.options;
                const maxValue = values.conditions[index]?.os_versions_max;

                if (value && maxValue) {
                  return isSmallerThan(value, maxValue) || value === maxValue;
                }
                return true;
              },
            ),
          os_versions_max: Yup.string()
            .matches(SEMVER_REGEXP, 'The value does not match the semver regular expression')
            .nullable().test(
              'check-os-max',
              'Max value should be greater than or equal to the min',
              (value, context) => {
                const { index } = context.options;
                const minValue = values.conditions[index]?.os_versions_min;

                if (value && minValue) {
                  return isSmallerThan(minValue, value) || value === minValue;
                }
                return true;
              },
            ),
          app_versions_min: Yup.string()
            .matches(SEMVER_REGEXP, 'The value does not match the semver regular expression')
            .nullable().test(
              'check-app-min',
              'Min value should be less than or equal to the max',
              (value, context) => {
                const { index } = context.options;
                const maxValue = values.conditions[index]?.app_versions_max;

                if (value && maxValue) {
                  return isSmallerThan(value, maxValue) || value === maxValue;
                }
                return true;
              },
            ),
          app_versions_max: Yup.string()
            .matches(SEMVER_REGEXP, 'The value does not match the semver regular expression')
            .nullable().test(
              'check-app-max',
              'Max value should be greater than or equal to the min',
              (value, context) => {
                const { index } = context.options;
                const minValue = values.conditions[index]?.app_versions_min;

                if (value && minValue) {
                  return isSmallerThan(minValue, value) || value === minValue;
                }
                return true;
              },
            ),
          install_dates_min: Yup.string().nullable(),
          install_dates_max: Yup.string().nullable(),
          custom_target: Yup.string().matches(/^[a-zA-Z0-9 (){}_:.,+-]*$/, 'Only latin letters, digits and (){}_:.,+- is required')
            .max(255, 'Max 255 symbols required').nullable(),
          install_dates_special: Yup.object().shape({
            seconds: Yup.string(),
            statement: Yup.string().nullable(),
          }).nullable().test(
            'check-install-dates',
            'Fields should not be empty',
            (items) => {
              const objValues = Object.values(items);

              return isEmpty(objValues) || objValues.length === 2;
            },
          ),
        }),
      ).nullable(),
    })
  );
});


const FormCreateEditAbTest = ({
  onSubmit,
  onCancel,
  initialValues,
  defaultAbTestStatus,
  isSubmitting,
  formErrors,
  isAbTestEdit,
  segmentList,
}) => {
  return (
    <Formik
      enableReinitialize
      validationSchema={validationSchema}
      isSubmitting={isSubmitting}
      initialValues={{ ...initialValues, segmentOptions: segmentList }}
      initialErrors={formErrors}
      onSubmit={(values) => {
        onSubmit(values);
      }}
    >
      {(props) => {
        // eslint-disable-next-line react/prop-types
        const { isValid, setFieldValue, dirty, values, errors } = props;

        const conditionsCopy = cloneDeep(values.conditions);
        const clearedConditions = conditionsCopy?.map((item) => getObjectDeepClean(item)) ?? [];
        const isValidConditionsArr = clearedConditions.map((item) => Object.keys(item).length > 1);
        const clearedConditionsIndexMap = isValidConditionsArr.map((item, index) => !item ? `#${index + 1}` : '').filter((arrItem) => !isEmpty(arrItem));

        const canEditForm = initialValues?.status !== AB_TEST_STATUS_MAP.ARCHIVED;
        const canEditMainDetails = [ AB_TEST_STATUS_MAP.DRAFT, null ].includes(defaultAbTestStatus);

        return (
          <Spin spinning={isAbTestEdit && isEmpty(initialValues)} tip="Loading form data...">
            <Form layout="vertical" style={{ width: '100%' }}>
              <Row gutter={[ 16, 16 ]}>
                <Col xs={24} md={12}>
                  <Divider orientation="left" className='dashboard-widgets-divider'>
                    Main details
                  </Divider>
                  <Row gutter={[ 16, 16 ]}>
                    <Col span={24}>
                      <Card>
                        <Row gutter={16}>
                          <Col xs={24} md={12}>
                            <FormItem
                              className='ant-form-item-col'
                              name="name"
                              label="Name:"
                              required={!isAbTestEdit}
                            >
                              <Input
                                fast
                                name='name'
                                placeholder='Specify ab-test name'
                                disabled={isAbTestEdit}
                                onChange={(event) => {
                                  setFieldValue('name', event.target.value.replace(/\s/g, ''));
                                }}
                              />
                            </FormItem>
                          </Col>

                          <Col xs={24} md={12}>
                            <FormItem
                              className='ant-form-item-col'
                              name="status"
                              label="Status:"
                              required
                            >
                              <Select
                                name="status"
                                placeholder="Select status"
                                value={values.status}
                                disabled={!canEditForm}
                              >
                                {initialValues?.status === AB_TEST_STATUS_MAP.DRAFT && (
                                  <Option value={AB_TEST_STATUS_MAP.DRAFT}>{capitalize(AB_TEST_STATUS_MAP.DRAFT)}</Option>
                                )}
                                <Option value={AB_TEST_STATUS_MAP.ACTIVE}>{capitalize(AB_TEST_STATUS_MAP.ACTIVE)}</Option>
                                <Option value={AB_TEST_STATUS_MAP.PAUSED}>{capitalize(AB_TEST_STATUS_MAP.PAUSED)}</Option>
                                <Option value={AB_TEST_STATUS_MAP.ARCHIVED}>{capitalize(AB_TEST_STATUS_MAP.ARCHIVED)}</Option>
                              </Select>
                            </FormItem>
                          </Col>

                          {isAbTestEdit && (
                            <>
                              <Col xs={24} md={8}>
                                <FormItem
                                  className='ant-form-item-col'
                                  name="created_at"
                                  label="Created at:"
                                >
                                  <Input
                                    fast
                                    name='created_at'
                                    value={values.created_at}
                                    disabled
                                  />
                                </FormItem>
                              </Col>

                              <Col xs={24} md={8}>
                                <FormItem
                                  className='ant-form-item-col'
                                  name="started_at"
                                  label="Started at:"
                                >
                                  <Input
                                    fast
                                    name='started_at'
                                    value={values.started_at}
                                    disabled
                                  />
                                </FormItem>
                              </Col>

                              <Col xs={24} md={8}>
                                <FormItem
                                  className='ant-form-item-col'
                                  name="finished_at"
                                  label="Finished at:"
                                >
                                  <Input
                                    fast
                                    name="finished_at"
                                    value={values.finished_at}
                                    disabled
                                  />
                                </FormItem>
                              </Col>
                            </>
                          )}

                          <Col xs={24} md={12}>
                            <FormItem
                              className='ant-form-item-col'
                              name="affiliation"
                              label="Affiliation:"
                              required
                            >
                              <Select
                                name="affiliation"
                                placeholder="Select affiliation"
                                value={values.affiliation}
                                disabled={!canEditMainDetails}
                              >
                                {AB_TEST_AFFILIATION_OPTIONS.map((item) => (
                                  <Option key={item.value} value={item.value}>
                                    {item.label}
                                  </Option>
                                ))}
                              </Select>
                            </FormItem>
                          </Col>
                          <Col xs={24} md={12}>
                            <FormItem
                              className='ant-form-item-col'
                              name="link"
                              label="Link:"
                            >
                              <Input
                                fast
                                name='link'
                                placeholder='Specify link'
                                disabled={!canEditForm}
                                onChange={(event) => {
                                  setFieldValue('link', event.target.value.replace(/\s/g, ''));
                                }}
                              />
                            </FormItem>
                          </Col>

                          <Col xs={24} md={12}>
                            <FormItem
                              className='ant-form-item-col'
                              name="priority"
                              label="Priority:"
                              required
                            >
                              <Input
                                fast
                                name='priority'
                                placeholder='Specify priority'
                                onChange={(event) => {
                                  setFieldValue('priority', event.target.value.replace(/\D/g, ''));
                                }}
                              />
                            </FormItem>
                          </Col>

                          <Col xs={24} md={12}>
                            <FormItem
                              className='ant-form-item-col'
                              name="percent"
                              label="Percent:"
                              required
                            >
                              <Input
                                fast
                                name='percent'
                                disabled={!canEditMainDetails}
                                placeholder='Specify percent'
                                onChange={(event) => {
                                  setFieldValue('percent', event.target.value.replace(/\D/g, ''));
                                }}
                              />
                            </FormItem>
                          </Col>

                          <Col span={24}>
                            <FormItem
                              className='ant-form-item-col'
                              name="description"
                              label="Description:"
                              required
                            >
                              <Input.TextArea
                                fast
                                name='description'
                                disabled={!canEditForm}
                                placeholder='Specify description'
                                rows={7}
                                showCount
                                maxLength={255}
                              />
                            </FormItem>
                          </Col>
                        </Row>
                      </Card>
                    </Col>
                  </Row>
                </Col>

                <Col xs={24} md={12}>
                  <Divider orientation="left" className='dashboard-widgets-divider'>
                    Groups
                  </Divider>
                  <Row gutter={[ 16, 16 ]}>
                    <Col span={24}>
                      <FieldArray
                        name="groups"
                        value={values.groups}
                      >
                        {({ remove, push }) => {
                          return (
                            <>
                              {!isEmpty(values?.groups) && values.groups.map((group, index) => (
                                <Card
                                  /* eslint-disable-next-line react/no-array-index-key */
                                  key={index}
                                  title={`Group # ${index + 1}`}
                                  extra={
                                    <>
                                      {canEditMainDetails && values.groups.length > 2 && (
                                        <Popconfirm title="Sure to delete this group?" onConfirm={() => remove(index)}>
                                          <Button
                                            size='small'
                                            danger
                                            icon={<DeleteOutlined />}
                                          />
                                        </Popconfirm>
                                      )}
                                    </>
                                  }
                                >
                                  {/* eslint-disable-next-line react/no-array-index-key */}
                                  <FastField name={`groups.${index}`} key={index}>
                                    {({ field: { name }, form: { setFieldValue } }) => {
                                      return (
                                        <Row gutter={16}>
                                          <Col xs={24} md={12}>
                                            <FormItem
                                              className='ant-form-item-col'
                                              name={`${name}.name`}
                                              label="Group name:"
                                              required
                                            >
                                              <Input
                                                fast
                                                name={`${name}.name`}
                                                placeholder='Specify group name'
                                                disabled={!canEditMainDetails}
                                                onChange={(event) => {
                                                  setFieldValue(`${name}.name`, event.target.value.replace(/\s/g, ''));
                                                }}
                                              />
                                            </FormItem>
                                          </Col>
                                          <Col xs={24} md={12}>
                                            <FormItem
                                              className='ant-form-item-col'
                                              name={`${name}.percent`}
                                              label="Percent:"
                                              required
                                            >
                                              <Input
                                                fast
                                                name={`${name}.percent`}
                                                placeholder='Specify percent'
                                                disabled={!canEditMainDetails}
                                                onChange={(event) => {
                                                  setFieldValue(`${name}.percent`, event.target.value.replace(/\D/g, ''));
                                                }}
                                              />
                                            </FormItem>
                                          </Col>
                                        </Row>
                                      );
                                    }}
                                  </FastField>
                                </Card>
                              )) }
                              {canEditMainDetails && (
                                <Row align='end'>
                                  <Button
                                    type='primary'
                                    icon={<RiAddFill />}
                                    onClick={() => push({ name: '', percent: null })}
                                  >
                                    Add group
                                  </Button>
                                </Row>
                              )}
                            </>
                          );
                        }}
                      </FieldArray>
                    </Col>
                  </Row>
                </Col>

                <Col span={24}>
                  <Divider orientation="left" className='dashboard-widgets-divider'>
                    Conditions
                  </Divider>

                  <FieldArray
                    name="conditions"
                    value={values.conditions}
                  >
                    {({ remove, push }) => {
                      return (
                        <>
                          {!isEmpty(values.conditions) ? values.conditions.map((condition, index) => (
                            <Card
                              /* eslint-disable-next-line react/no-array-index-key */
                              key={index}
                              title={`Condition # ${index + 1}`}
                              extra={
                                <>
                                  {canEditForm && (
                                    <Popconfirm title="Sure to delete this condition?" onConfirm={() => remove(index)}>
                                      <Button
                                        size='small'
                                        danger
                                        icon={<DeleteOutlined />}
                                      />
                                    </Popconfirm>
                                  )}
                                </>
                              }
                            >
                              {/* eslint-disable-next-line react/no-array-index-key */}
                              <FastField name={`conditions.${index}`} key={index}>
                                {({ field: { name }, form: { setFieldValue } }) => {
                                  return (
                                    <>
                                      <Row gutter={16}>
                                        <Col xs={24} sm={12} md={8} lg={8}>
                                          <FormItem
                                            className='ant-form-item-col'
                                            name={`${name}.condition_type`}
                                            label="Condition type:"
                                            required
                                          >
                                            <Select
                                              name={`${name}.condition_type`}
                                              placeholder="Specify condition type"
                                              disabled={!canEditForm}
                                              onChange={(value) => {
                                                setFieldValue(`${name}.condition_type`, value);
                                              }}
                                              value={values.conditions[index].condition_type}
                                            >
                                              <Option value='include'>Include specific locations</Option>
                                              <Option value='exclude'>Exclude these locations</Option>
                                            </Select>
                                          </FormItem>
                                        </Col>

                                        <Col xs={24} sm={12} md={8} lg={8}>
                                          <FormItem
                                            className='ant-form-item-col'
                                            name={`${name}.countries`}
                                            label="Countries:"
                                          >
                                            <Select
                                              mode="multiple"
                                              allowClear
                                              showSearch
                                              name={`${name}.countries`}
                                              onChange={(value) => {
                                                setFieldValue(`${name}.countries`, value);
                                              }}
                                              placeholder="Select country"
                                              disabled={!canEditForm}
                                              value={values.conditions[index].countries}
                                              optionFilterProp="children"
                                              filterOption={(input, option) => mkSelectFilterOption(option.children, input)}
                                              filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
                                            >
                                              {COUNTRIES_LIST.map((option) => {
                                                return (
                                                  <Option key={option.code} value={option.code}>
                                                    {`${option.code} - ${option.name}`}
                                                  </Option>
                                                );
                                              })}
                                            </Select>
                                          </FormItem>
                                        </Col>

                                        <Col xs={24} sm={12} md={8} lg={8}>
                                          <FormItem
                                            className='ant-form-item-col'
                                            name={`${name}.languages`}
                                            label="Languages:"
                                          >
                                            <Select
                                              allowClear
                                              showSearch
                                              mode="multiple"
                                              name={`${name}.languages`}
                                              onChange={(value) => {
                                                setFieldValue(`${name}.languages`, value);
                                              }}
                                              placeholder="Select language(s)"
                                              disabled={!canEditForm}
                                              value={values.conditions[index]?.languages}
                                              optionFilterProp="children"
                                              filterOption={(input, option) => mkSelectFilterOption(option.children, input)}
                                              filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
                                            >
                                              {AB_TESTS_LANGUAGES_OPTIONS.map((option) => {
                                                return (
                                                  <Option key={option.key} value={option.key}>
                                                    {option.label}
                                                  </Option>
                                                );
                                              })}
                                            </Select>
                                          </FormItem>
                                        </Col>

                                        <Col xs={24} sm={12} md={8} lg={8}>
                                          <FormItem
                                            className='ant-form-item-col'
                                            name={`${name}.device_types`}
                                            label="Device types:"
                                          >
                                            <Select
                                              mode="multiple"
                                              allowClear
                                              showSearch
                                              name={`${name}.device_types`}
                                              onChange={(value) => {
                                                setFieldValue(`${name}.device_types`, value);
                                              }}
                                              placeholder="Select device types"
                                              disabled={!canEditForm}
                                              value={values.conditions[index].device_types}
                                              optionFilterProp="children"
                                              filterOption={(input, option) => mkSelectFilterOption(option.children, input)}
                                              filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
                                            >
                                              {DEVICE_TYPE_OPTIONS.map((option) => {
                                                return (
                                                  <Option key={option.value} value={option.value}>
                                                    {option.label}
                                                  </Option>
                                                );
                                              })}
                                            </Select>
                                          </FormItem>
                                        </Col>

                                        <Col xs={24} sm={12} md={8} lg={8}>
                                          <FormItem
                                            className='ant-form-item-col'
                                            name={`${name}.device_manufactures`}
                                            label="Device manufactures:"
                                          >
                                            <Select
                                              mode="multiple"
                                              allowClear
                                              showSearch
                                              name={`${name}.device_manufactures`}
                                              onChange={(value) => {
                                                setFieldValue(`${name}.device_manufactures`, value);
                                              }}
                                              placeholder="Select device manufactures"
                                              disabled={!canEditForm}
                                              value={values.conditions[index].device_manufactures}
                                              optionFilterProp="children"
                                              filterOption={(input, option) => mkSelectFilterOption(option.children, input)}
                                              filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
                                            >
                                              {DEVICE_MANUFACTURES_OPTIONS.map((option) => {
                                                return (
                                                  <Option key={option.value} value={option.value}>
                                                    {option.label}
                                                  </Option>
                                                );
                                              })}
                                            </Select>
                                          </FormItem>
                                        </Col>

                                        <Col xs={24} sm={12} md={8} lg={8}>
                                          <FormItem
                                            className='ant-form-item-col'
                                            name={`${name}.device_models`}
                                            label="Device models:"
                                          >
                                            <Select
                                              mode="multiple"
                                              showSearch={false}
                                              allowClear
                                              disabled={!canEditForm}
                                              name={`${name}.device_models`}
                                              onChange={(value) => {
                                                setFieldValue(`${name}.device_models`, value);
                                              }}
                                              value={values.conditions[index].device_models}
                                              placeholder="Specify device model(s)"
                                              dropdownRender={(menu) => (
                                                <div>
                                                  {menu}
                                                  <Divider style={{ margin: '4px 0' }} />
                                                  <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                                                    <Input
                                                      fast
                                                      size='small'
                                                      style={{ flex: 'auto' }}
                                                      name={`${name}.input_device_model`}
                                                      onKeyDown={(event) => event.stopPropagation()}
                                                      value={values.conditions[index].input_device_model}
                                                      onChange={(event) => {
                                                        setFieldValue(`${name}.input_device_model`, event.target.value, false);
                                                      }}
                                                    />
                                                    <Button
                                                      type="primary"
                                                      size='small'
                                                      disabled={(!isEmpty(values.conditions[index]?.device_models) ?
                                                        values.conditions[index]?.device_models.includes(values.conditions[index]?.input_device_model) : false)
                                                      || isEmpty(values.conditions[index]?.input_device_model)}
                                                      style={{ flex: 'none', margin: '4px', display: 'block', cursor: 'pointer' }}
                                                      onClick={() => {
                                                        setFieldValue(`${name}.device_models`, [ ...values.conditions[index]?.device_models, values.conditions[index]?.input_device_model ], false);
                                                        setFieldValue(`${name}.input_device_model`, '', false);
                                                      }}
                                                    >
                                                      <PlusOutlined />
                                                      Add item
                                                    </Button>
                                                  </div>
                                                </div>
                                              )}
                                            />
                                          </FormItem>
                                        </Col>

                                        <Col xs={24} sm={12} md={8} lg={8}>
                                          <FormItem
                                            className='ant-form-item-col disable-ant-form-margin'
                                            name={`${name}.device_performance`}
                                            label='Device performance:'
                                          >
                                            <Row>
                                              <Col span={11}>
                                                <FormItem
                                                  className='ant-form-item-col'
                                                  style={{ margin: 'unset !important', padding: 'unset !important' }}
                                                  name={`${name}.device_performance_min`}
                                                >
                                                  <Input
                                                    type='number'
                                                    name={`${name}.device_performance_min`}
                                                    onChange={(event) => {
                                                      setFieldValue(`${name}.device_performance_min`, event.target.value);
                                                      setFieldValue(`${name}.device_performance.min`, event.target.value);
                                                    }}
                                                    placeholder='Min performance value'
                                                    disabled={!canEditForm || (!isEmpty(values.conditions[index]?.device_performance_list) && !values.conditions[index]?.device_performance_min)}
                                                  />
                                                </FormItem>
                                              </Col>

                                              <Col span={2}>
                                                <TFormDateRangeDivider>&ndash;</TFormDateRangeDivider>
                                              </Col>
                                              <Col span={11}>
                                                <FormItem
                                                  className='ant-form-item-col'
                                                  name={`${name}.device_performance_max`}
                                                >
                                                  <Input
                                                    type='number'
                                                    name={`${name}.device_performance_max`}
                                                    onChange={(event) => {
                                                      setFieldValue(`${name}.device_performance_max`, event.target.value);
                                                      setFieldValue(`${name}.device_performance.max`, event.target.value);
                                                    }}
                                                    placeholder='Max performance value'
                                                    disabled={!canEditForm || (!isEmpty(values.conditions[index]?.device_performance_list) && !values.conditions[index]?.device_performance_max)}
                                                  />
                                                </FormItem>
                                              </Col>
                                            </Row>
                                          </FormItem>
                                        </Col>

                                        <Col xs={24} sm={12} md={16} lg={16}>
                                          <FormItem
                                            className='ant-form-item-col disable-ant-form-margin'
                                            name={`${name}.device_performance`}
                                            label='Device performance list:'
                                          >
                                            <FormItem
                                              className='ant-form-item-col'
                                              name={`${name}.device_performance_list`}
                                            >
                                              <Select
                                                mode="multiple"
                                                allowClear
                                                showSearch={false}
                                                disabled={!canEditForm || ((values.conditions[index].device_performance_min || values.conditions[index].device_performance_max) && isEmpty(values.conditions[index]?.device_performance_list))}
                                                name={`${name}.device_performance_list`}
                                                onChange={(value) => {
                                                  setFieldValue(`${name}.device_performance_list`, value);
                                                  setFieldValue(`${name}.device_performance.list`, value);
                                                }}
                                                value={values.conditions[index].device_performance_list}
                                                placeholder="Specify device performance value(s)"
                                                dropdownRender={(menu) => (
                                                  <div>
                                                    {menu}
                                                    <Divider style={{ margin: '4px 0' }} />
                                                    <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                                                      <FormItem
                                                        className='ant-form-item-col ant-form-item-custom-option'
                                                        name={`${name}.input_device_performance_list`}
                                                      >
                                                        <Input
                                                          size='small'
                                                          type='number'
                                                          style={{ flex: 'auto' }}
                                                          name={`${name}.input_device_performance_list`}
                                                          onKeyDown={(event) => event.stopPropagation()}
                                                          value={values.conditions[index].input_device_performance_list}
                                                          onChange={(event) => {
                                                            if (Number(event.target.value)) {
                                                              setFieldValue(`${name}.input_device_performance_list`, Number(event.target.value), true);
                                                            }
                                                          }}
                                                        />
                                                      </FormItem>
                                                      <Button
                                                        type="primary"
                                                        size='small'
                                                        disabled={
                                                          (!isEmpty(values.conditions[index]?.device_performance_list) ?
                                                            values.conditions[index]?.device_performance_list.includes(values.conditions[index]?.input_device_performance_list) : false)
                                                        || (errors?.conditions ? !isEmpty(errors?.conditions[index]?.input_device_performance_list) : false)
                                                        || !isNumber(values.conditions[index]?.input_device_performance_list)
                                                        }
                                                        className='ant-form-item-custom-option-btn'
                                                        onClick={() => {
                                                          setFieldValue(`${name}.device_performance_list`, [ ...values.conditions[index]?.device_performance_list, values.conditions[index]?.input_device_performance_list ], false);
                                                          setFieldValue(`${name}.input_device_performance_list`, null, true);
                                                        }}
                                                      >
                                                        <PlusOutlined />
                                                        Add value
                                                      </Button>
                                                    </div>
                                                  </div>
                                                )}
                                              />
                                            </FormItem>
                                          </FormItem>
                                        </Col>

                                        <Col xs={24} sm={12} md={8} lg={8}>
                                          <FormItem
                                            className='ant-form-item-col disable-ant-form-margin'
                                            name={`${name}.ram`}
                                            label='Ram (Mb):'
                                          >
                                            <Row>
                                              <Col span={11}>
                                                <FormItem
                                                  className='ant-form-item-col'
                                                  style={{ margin: 'unset !important', padding: 'unset !important' }}
                                                  name={`${name}.ram_min`}
                                                >
                                                  <Input
                                                    type='number'
                                                    name={`${name}.ram_min`}
                                                    onChange={(event) => {
                                                      setFieldValue(`${name}.ram_min`, event.target.value);
                                                      setFieldValue(`${name}.ram.min`, event.target.value);
                                                    }}
                                                    placeholder='Min ram value'
                                                    disabled={!canEditForm || (!isEmpty(values.conditions[index]?.ram_list) && !values.conditions[index]?.ram_min)}
                                                  />
                                                </FormItem>
                                              </Col>

                                              <Col span={2}>
                                                <TFormDateRangeDivider>&ndash;</TFormDateRangeDivider>
                                              </Col>
                                              <Col span={11}>
                                                <FormItem
                                                  className='ant-form-item-col'
                                                  name={`${name}.ram_max`}
                                                >
                                                  <Input
                                                    type='number'
                                                    name={`${name}.ram_max`}
                                                    onChange={(event) => {
                                                      setFieldValue(`${name}.ram_max`, event.target.value);
                                                      setFieldValue(`${name}.ram.max`, event.target.value);
                                                    }}
                                                    placeholder='Max ram value'
                                                    disabled={!canEditForm || (!isEmpty(values.conditions[index]?.ram_list) && !values.conditions[index]?.ram_max)}
                                                  />
                                                </FormItem>
                                              </Col>
                                            </Row>
                                          </FormItem>
                                        </Col>

                                        <Col xs={24} sm={12} md={16} lg={16}>
                                          <FormItem
                                            className='ant-form-item-col disable-ant-form-margin'
                                            name={`${name}.ram_list`}
                                            label='Ram list:'
                                          >
                                            <FormItem
                                              className='ant-form-item-col'
                                              name={`${name}.ram_list`}
                                            >
                                              <Select
                                                mode="multiple"
                                                allowClear
                                                showSearch={false}
                                                disabled={!canEditForm || ((values.conditions[index].ram_min || values.conditions[index].ram_max) && isEmpty(values.conditions[index]?.ram_list))}
                                                name={`${name}.ram_list`}
                                                onChange={(value) => {
                                                  setFieldValue(`${name}.ram_list`, value);
                                                  setFieldValue(`${name}.ram.list`, value);
                                                }}
                                                placeholder="Specify ram value(s)"
                                                dropdownRender={(menu) => (
                                                  <div>
                                                    {menu}
                                                    <Divider style={{ margin: '4px 0' }} />
                                                    <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                                                      <FormItem
                                                        className='ant-form-item-col ant-form-item-custom-option'
                                                        name={`${name}.input_ram_list`}
                                                      >
                                                        <Input
                                                          size='small'
                                                          type='number'
                                                          style={{ flex: 'auto' }}
                                                          name={`${name}.input_ram_list`}
                                                          onKeyDown={(event) => event.stopPropagation()}
                                                          value={values.conditions[index].input_ram_list}
                                                          onChange={(event) => {
                                                            if (Number(event.target.value)) {
                                                              setFieldValue(`${name}.input_ram_list`, Number(event.target.value), true);
                                                            }
                                                          }}
                                                        />
                                                      </FormItem>
                                                      <Button
                                                        type="primary"
                                                        size='small'
                                                        disabled={
                                                          (!isEmpty(values.conditions[index]?.ram_list) ?
                                                            values.conditions[index]?.ram_list.includes(values.conditions[index]?.input_ram_list) : false)
                                                        || (errors?.conditions ? !isEmpty(errors?.conditions[index]?.input_ram_list) : false)
                                                        || !isNumber(values.conditions[index]?.input_ram_list)
                                                        }
                                                        className='ant-form-item-custom-option-btn'
                                                        onClick={() => {
                                                          setFieldValue(`${name}.ram_list`, [ ...values.conditions[index]?.ram_list, values.conditions[index]?.input_ram_list ], false);
                                                          setFieldValue(`${name}.input_ram_list`, null, true);
                                                        }}
                                                      >
                                                        <PlusOutlined />
                                                        Ram value
                                                      </Button>
                                                    </div>
                                                  </div>
                                                )}
                                              />
                                            </FormItem>
                                          </FormItem>
                                        </Col>

                                        <Col xs={24} sm={12} md={8} lg={8}>
                                          <FormItem
                                            className='ant-form-item-col disable-ant-form-margin'
                                            name={`${name}.os_versions`}
                                            label='OS versions:'
                                          >
                                            <Row>
                                              <Col span={11}>
                                                <FormItem
                                                  className='ant-form-item-col'
                                                  style={{ margin: 'unset !important', padding: 'unset !important' }}
                                                  name={`${name}.os_versions_min`}
                                                >
                                                  <Input
                                                    name={`${name}.os_versions_min`}
                                                    placeholder='Min OS version'
                                                    disabled={!canEditForm || (!isEmpty(values.conditions[index]?.os_versions_list) && !values.conditions[index]?.os_versions_min)}
                                                  />
                                                </FormItem>
                                              </Col>

                                              <Col span={2}>
                                                <TFormDateRangeDivider>&ndash;</TFormDateRangeDivider>
                                              </Col>
                                              <Col span={11}>
                                                <FormItem
                                                  className='ant-form-item-col'
                                                  name={`${name}.os_versions_max`}
                                                >
                                                  <Input
                                                    name={`${name}.os_versions_max`}
                                                    placeholder='Max OS version'
                                                    disabled={!canEditForm || (!isEmpty(values.conditions[index]?.os_versions_list) && !values.conditions[index]?.os_versions_max)}
                                                  />
                                                </FormItem>
                                              </Col>
                                            </Row>
                                          </FormItem>
                                        </Col>

                                        <Col xs={24} sm={12} md={16} lg={16}>
                                          <FormItem
                                            className='ant-form-item-col disable-ant-form-margin'
                                            name={`${name}.os_versions`}
                                            label='OS versions list:'
                                          >
                                            <FormItem
                                              className='ant-form-item-col'
                                              name={`${name}.os_versions_list`}
                                            >
                                              <Select
                                                mode="multiple"
                                                allowClear
                                                showSearch={false}
                                                disabled={!canEditForm || ((values.conditions[index].os_versions_min || values.conditions[index].os_versions_max) && isEmpty(values.conditions[index]?.os_versions_list))}
                                                name={`${name}.os_versions_list`}
                                                onChange={(value) => {
                                                  setFieldValue(`${name}.os_versions_list`, value);
                                                }}
                                                value={values.conditions[index].os_versions_list}
                                                placeholder="Specify os version(s)"
                                                dropdownRender={(menu) => (
                                                  <div>
                                                    {menu}
                                                    <Divider style={{ margin: '4px 0' }} />
                                                    <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                                                      <FormItem
                                                        className='ant-form-item-col ant-form-item-custom-option'
                                                        name={`${name}.input_os_versions_list`}
                                                      >
                                                        <Input
                                                          size='small'
                                                          style={{ flex: 'auto' }}
                                                          name={`${name}.input_os_versions_list`}
                                                          onKeyDown={(event) => event.stopPropagation()}
                                                          value={values.conditions[index].input_os_versions_list}
                                                          onChange={(event) => {
                                                            setFieldValue(`${name}.input_os_versions_list`, event.target.value, true);
                                                          }}
                                                        />
                                                      </FormItem>
                                                      <Button
                                                        type="primary"
                                                        size='small'
                                                        disabled={
                                                          (!isEmpty(values.conditions[index]?.os_versions_list) ?
                                                            values.conditions[index]?.os_versions_list.includes(values.conditions[index]?.input_os_versions_list) : false)
                                                        || (errors?.conditions ? !isEmpty(errors?.conditions[index]?.input_os_versions_list) : false)
                                                        || isEmpty(values.conditions[index]?.input_os_versions_list)
                                                        }
                                                        className='ant-form-item-custom-option-btn'
                                                        onClick={() => {
                                                          setFieldValue(`${name}.os_versions_list`, [ ...values.conditions[index]?.os_versions_list, values.conditions[index]?.input_os_versions_list ], false);
                                                          setFieldValue(`${name}.input_os_versions_list`, '', true);
                                                        }}
                                                      >
                                                        <PlusOutlined />
                                                        OS version
                                                      </Button>
                                                    </div>
                                                  </div>
                                                )}
                                              />
                                            </FormItem>
                                          </FormItem>
                                        </Col>

                                        <Col xs={24} sm={12} md={8} lg={8}>
                                          <FormItem
                                            className='ant-form-item-col disable-ant-form-margin'
                                            name={`${name}.app_versions`}
                                            label='App versions:'
                                          >
                                            <Row>
                                              <Col span={11}>
                                                <FormItem
                                                  className='ant-form-item-col'
                                                  name={`${name}.app_versions_min`}
                                                >
                                                  <Input
                                                    name={`${name}.app_versions_min`}
                                                    placeholder='Min app version'
                                                    disabled={!canEditForm || (!isEmpty(values.conditions[index]?.app_versions_list) && !values.conditions[index]?.app_versions_min)}
                                                  />
                                                </FormItem>
                                              </Col>

                                              <Col span={2}>
                                                <TFormDateRangeDivider>&ndash;</TFormDateRangeDivider>
                                              </Col>
                                              <Col span={11}>
                                                <FormItem
                                                  className='ant-form-item-col disable-ant-form-margin'
                                                  name={`${name}.app_versions_max`}
                                                >
                                                  <Input
                                                    name={`${name}.app_versions_max`}
                                                    placeholder='Max app version'
                                                    disabled={!canEditForm || (!isEmpty(values.conditions[index]?.app_versions_list) && !values.conditions[index]?.app_versions_max)}
                                                  />
                                                </FormItem>
                                              </Col>
                                            </Row>
                                          </FormItem>
                                        </Col>

                                        <Col xs={24} sm={12} md={16} lg={16}>
                                          <FormItem
                                            className='ant-form-item-col disable-ant-form-margin'
                                            name={`${name}.app_versions`}
                                            label='App versions list:'
                                          >
                                            <FormItem
                                              className='ant-form-item-col'
                                              name={`${name}.app_versions_list`}
                                            >
                                              <Select
                                                mode="multiple"
                                                allowClear
                                                showSearch={false}
                                                disabled={!canEditForm || ((values.conditions[index].app_versions_min || values.conditions[index].app_versions_max) && isEmpty(values.conditions[index]?.app_versions_list))}
                                                name={`${name}.app_versions_list`}
                                                onChange={(value) => {
                                                  setFieldValue(`${name}.app_versions_list`, value);
                                                }}
                                                placeholder="Specify app version(s)"
                                                dropdownRender={(menu) => (
                                                  <div>
                                                    {menu}
                                                    <Divider style={{ margin: '4px 0' }} />
                                                    <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                                                      <FormItem
                                                        className='ant-form-item-col ant-form-item-custom-option'
                                                        name={`${name}.input_app_versions_list`}
                                                      >
                                                        <Input
                                                          size='small'
                                                          style={{ flex: 'auto' }}
                                                          name={`${name}.input_app_versions_list`}
                                                          onKeyDown={(event) => event.stopPropagation()}
                                                          value={values.conditions[index].input_app_versions_list}
                                                          onChange={(event) => {
                                                            setFieldValue(`${name}.input_app_versions_list`, event.target.value, true);
                                                          }}
                                                        />
                                                      </FormItem>
                                                      <Button
                                                        type="primary"
                                                        size='small'
                                                        disabled={
                                                          (!isEmpty(values.conditions[index]?.app_versions_list) ?
                                                            values.conditions[index]?.app_versions_list.includes(values.conditions[index]?.input_app_versions_list) : false)
                                                        || (errors?.conditions ? !isEmpty(errors?.conditions[index]?.input_app_versions_list) : false)
                                                        || isEmpty(values.conditions[index]?.input_app_versions_list)
                                                        }
                                                        className='ant-form-item-custom-option-btn'
                                                        onClick={() => {
                                                          setFieldValue(`${name}.app_versions_list`, [ ...values.conditions[index]?.app_versions_list, values.conditions[index]?.input_app_versions_list ], false);
                                                          setFieldValue(`${name}.input_app_versions_list`, '', true);
                                                        }}
                                                      >
                                                        <PlusOutlined />
                                                        App version
                                                      </Button>
                                                    </div>
                                                  </div>
                                                )}
                                              />
                                            </FormItem>
                                          </FormItem>
                                        </Col>

                                        <Col xs={24} sm={24} md={8} lg={8}>
                                          <FormItem
                                            className='ant-form-item-col'
                                            name={`${name}.platforms`}
                                            label="Platforms:"
                                          >
                                            <Select
                                              mode="multiple"
                                              allowClear
                                              showSearch
                                              name={`${name}.platforms`}
                                              onChange={(value) => {
                                                setFieldValue(`${name}.platforms`, value);
                                              }}
                                              placeholder="Select platform(s)"
                                              disabled={!canEditForm}
                                              value={values.conditions[index].platforms}
                                              optionFilterProp="children"
                                              filterOption={(input, option) => mkSelectFilterOption(option.children, input)}
                                              filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
                                            >
                                              {PLATFORM_OPTIONS.map((option) => {
                                                return (
                                                  <Option key={option.value} value={option.value}>
                                                    {option.label}
                                                  </Option>
                                                );
                                              })}
                                            </Select>
                                          </FormItem>
                                        </Col>

                                        <Col xs={16} sm={12} md={8} lg={8}>
                                          <FormItem
                                            className='ant-form-item-col'
                                            name={`${name}.law`}
                                            label="Law:"
                                          >
                                            <Select
                                              mode="multiple"
                                              allowClear
                                              showSearch
                                              name={`${name}.law`}
                                              onChange={(value) => {
                                                setFieldValue(`${name}.law`, value);
                                              }}
                                              placeholder="Specify law type"
                                              disabled={!canEditForm}
                                              value={values.conditions[index]?.law}
                                              optionFilterProp="children"
                                              filterOption={(input, option) => mkSelectFilterOption(option.children, input)}
                                              filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
                                            >
                                              {LAW_TYPES_OPTIONS.map((option) => {
                                                return (
                                                  <Option key={option.value} value={option.value}>
                                                    {option.label}
                                                  </Option>
                                                );
                                              })}
                                            </Select>
                                          </FormItem>
                                        </Col>

                                        <Col xs={8} sm={12} md={8} lg={8}>
                                          <FormItem
                                            className='ant-form-item-col'
                                            name={`${name}.lat`}
                                            label="Lat:"
                                          >
                                            <Select
                                              name={`${name}.lat`}
                                              placeholder="Select lat"
                                              onChange={(value) => {
                                                setFieldValue(`${name}.lat`, value);
                                              }}
                                              value={values.conditions[index].lat}
                                              disabled={!canEditForm}
                                            >
                                              {LAT_TYPES_OPTIONS.map((option) => {
                                                return (
                                                  <Option key={option.value} value={option.value}>
                                                    {option.label}
                                                  </Option>
                                                );
                                              })}
                                            </Select>
                                          </FormItem>
                                        </Col>

                                        <Col xs={24} sm={24} md={8} lg={8}>
                                          <FormItem
                                            className='ant-form-item-col disable-ant-form-margin'
                                            name={`${name}.install_dates`}
                                            label="Install dates (min-max):"
                                          >
                                            <Row>
                                              <Col span={11}>
                                                <FormItem
                                                  className='ant-form-item-col disable-ant-form-margin'
                                                  name={`${name}.install_dates_min`}
                                                >
                                                  <DatePicker
                                                    showTime
                                                    format='YYYY-MM-DD HH:mm:ss'
                                                    placeholder='Select min date'
                                                    name={`${name}.install_dates_min`}
                                                    disabled={!canEditForm || ((!isEmpty(values.conditions[index]?.install_dates_list)
                                                          || values.conditions[index]?.install_dates_special?.statement
                                                          || values.conditions[index]?.install_dates_special?.seconds)
                                                      && !values.conditions[index]?.install_dates_min)}
                                                    disabledDate={(date) => date.isSameOrAfter(values.conditions[index].install_dates_max)}
                                                    onChange={(value) => {
                                                      if (moment(value).diff(moment(values.conditions[index].install_dates_max)) > 0) {
                                                        setFieldValue(`${name}.install_dates_max`, null);
                                                      }
                                                      setFieldValue(`${name}.install_dates_min`, moment(value).toISOString());
                                                    }}
                                                  />
                                                </FormItem>
                                              </Col>

                                              <Col span={2}>
                                                <TFormDateRangeDivider>&ndash;</TFormDateRangeDivider>
                                              </Col>
                                              <Col span={11}>
                                                <FormItem
                                                  className='ant-form-item-col'
                                                  name={`${name}.install_dates_max`}
                                                >
                                                  <DatePicker
                                                    showTime
                                                    format='YYYY-MM-DD HH:mm:ss'
                                                    placeholder='Select max date'
                                                    name={`${name}.install_dates_max`}
                                                    disabled={!canEditForm || ((!isEmpty(values.conditions[index]?.install_dates_list)
                                                          || values.conditions[index]?.install_dates_special?.statement
                                                          || values.conditions[index]?.install_dates_special?.seconds)
                                                      && !values.conditions[index]?.install_dates_max)}
                                                    disabledDate={(date) => !date || date.isSameOrBefore(values.conditions[index].install_dates_min)}
                                                    onChange={(value) => {
                                                      if ((moment(values.conditions[index].install_dates_min).diff(moment(value))) > 0) {
                                                        setFieldValue(`${name}.install_dates_min`, null);
                                                      }
                                                      setFieldValue(`${name}.install_dates_max`, moment(value).toISOString());
                                                    }}
                                                  />
                                                </FormItem>
                                              </Col>
                                            </Row>
                                          </FormItem>
                                        </Col>

                                        <Col xs={24} sm={24} md={8} lg={8}>
                                          <FormItem
                                            className='ant-form-item-col'
                                            name={`${name}.install_dates_special`}
                                            label="Special install dates:"
                                          >
                                            <Input
                                              name={`${name}.install_dates_special.seconds`}
                                              placeholder='Specify seconds'
                                              disabled={!canEditForm || ((values.conditions[index].install_dates_min
                                                        || values.conditions[index].install_dates_max
                                                        || !isEmpty(values.conditions[index]?.install_dates_list))
                                                    && !values.conditions[index]?.install_dates_special?.statement)}
                                              onChange={(event) => {
                                                setFieldValue(`${name}.install_dates_special.seconds`, event.target.value.replace(/\D/g, ''));
                                              }}
                                              addonAfter={
                                                <Form.Item name={`${name}.install_dates_special.statement`} noStyle>
                                                  <Select
                                                    placeholder='Select value'
                                                    name={`${name}.install_dates_special.statement`}
                                                    onChange={(value) => {
                                                      setFieldValue(`${name}.install_dates_special.statement`, value);
                                                    }}
                                                    disabled={!canEditForm || ((values.conditions[index].install_dates_min
                                                              || values.conditions[index].install_dates_max
                                                              || !isEmpty(values.conditions[index]?.install_dates_list))
                                                          && !values.conditions[index]?.install_dates_special?.statement)}
                                                    required
                                                    style={{ width: 150 }}
                                                  >
                                                    <Option value=''>Not required</Option>
                                                    <Option value=">=">More or equal</Option>
                                                    <Option value="<=">Less or equal</Option>
                                                    <Option value=">">More than</Option>
                                                    <Option value="<">Less than</Option>
                                                  </Select>
                                                </Form.Item>
                                              }
                                              style={{ width: '100%' }}
                                            />
                                          </FormItem>
                                        </Col>

                                        <Col xs={24} sm={12} md={8} lg={8}>
                                          <FormItem
                                            className='ant-form-item-col disable-ant-form-margin'
                                            name='fake_install_dates_form_item'
                                            label='Install dates list:'
                                          >
                                            <FieldArray
                                              name={`${name}.install_dates_list`}
                                              value={values.conditions[index]?.install_dates_list}
                                            >
                                              {({ remove, push }) => {
                                                return (
                                                  <>
                                                    {!isEmpty(values.conditions[index].install_dates_list) && values.conditions[index].install_dates_list.map((dates, index) => (
                                                    // eslint-disable-next-line react/no-array-index-key
                                                      <FastField name={`dates.[${index}]`} key={index}>
                                                        {() => {
                                                          return (
                                                            <FormItem
                                                              className='ant-form-item-col disable-ant-form-margin'
                                                              name={`${name}.install_dates_list`}
                                                              showInitialErrorAfterTouched
                                                            >
                                                              <div className='da-d-flex'>
                                                                <RangePicker
                                                                  className='da-mb-8'
                                                                  showTime
                                                                  disabled={!canEditForm}
                                                                  format='YYYY-MM-DD HH:mm:ss'
                                                                  name={`${name}.install_dates_list.[${index}]`}
                                                                />
                                                                {canEditForm && (
                                                                  <Popconfirm title="Sure to delete this date range?" onConfirm={() => remove(index)}>
                                                                    <Button
                                                                      style={{ margin: '3px 0 0 5px' }}
                                                                      size='small'
                                                                      danger
                                                                      icon={<DeleteOutlined />}
                                                                    />
                                                                  </Popconfirm>
                                                                )}
                                                              </div>
                                                            </FormItem>
                                                          );
                                                        }}
                                                      </FastField>
                                                    ))}
                                                    <Button
                                                      className='da-mb-16'
                                                      type='primary'
                                                      size='small'
                                                      disabled={!isEmpty(values.conditions[index]?.install_dates_list)
                                                        ? values.conditions[index].install_dates_list.map((item) => isEmpty(item)).includes(true)
                                                        : (!canEditForm
                                                            || values.conditions[index]?.install_dates_special?.statement
                                                            || values.conditions[index]?.install_dates_special?.seconds
                                                            || values.conditions[index].install_dates_min
                                                            || values.conditions[index].install_dates_max)}
                                                      icon={<RiAddFill />}
                                                      onClick={() => push([])}
                                                    >
                                                      Add dates
                                                    </Button>
                                                  </>
                                                );
                                              }}
                                            </FieldArray>
                                          </FormItem>
                                        </Col>


                                      </Row>
                                      <Row gutter={[ 16, 16 ]}>
                                        <Col xs={16} sm={12} md={8} lg={8}>
                                          <FormItem
                                            className='ant-form-item-col'
                                            name={`${name}.segments`}
                                            label="Segments:"
                                          >
                                            <Select
                                              mode="multiple"
                                              allowClear
                                              showSearch
                                              name={`${name}.segments`}
                                              onChange={(value) => {
                                                setFieldValue(`${name}.segments`, value);
                                              }}
                                              placeholder="Specify segments"
                                              value={values.conditions[index]?.segments}
                                              disabled={!canEditForm}
                                              optionFilterProp="children"
                                              filterOption={(input, option) => mkSelectFilterOption(option.children, input)}
                                              filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
                                            >
                                              {!isEmpty(values.segmentOptions) ? (values.segmentOptions.map((option) => {
                                                return (
                                                  <Option key={option.name} value={option.name}>
                                                    {option.name}
                                                  </Option>
                                                );
                                              })) : null}
                                            </Select>
                                          </FormItem>
                                        </Col>
                                        <Col xs={24} sm={24} md={8} lg={8}>
                                          <FormItem
                                            className='ant-form-item-col'
                                            name={`${name}.users.types`}
                                            label="Users types:"
                                          >
                                            <Select
                                              mode="multiple"
                                              allowClear
                                              showSearch
                                              name={`${name}.users.types`}
                                              onChange={(value) => {
                                                setFieldValue(`${name}.users.types`, value);
                                              }}
                                              placeholder="Select users type(s)"
                                              disabled={!canEditForm}
                                              value={values.conditions[index]?.users?.types}
                                              optionFilterProp="children"
                                              filterOption={(input, option) => mkSelectFilterOption(option.children, input)}
                                              filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
                                            >
                                              {USERS_TYPES_OPTIONS.map((option) => {
                                                return (
                                                  <Option key={option.value} value={option.value}>
                                                    {option.label}
                                                  </Option>
                                                );
                                              })}
                                            </Select>
                                          </FormItem>
                                        </Col>

                                        <Col xs={24} sm={24} md={8} lg={8}>
                                          <FormItem
                                            className='ant-form-item-col disable-ant-form-margin'
                                            name='fake_user_id_form_item'
                                            label='Users IDs list:'
                                          >
                                            <FieldArray
                                              name={`${name}.users.list`}
                                              value={values.conditions[index]?.users?.list}
                                            >
                                              {({ remove, push }) => {
                                                return (
                                                  <>
                                                    {!isEmpty(values.conditions[index]?.users?.list) && values.conditions[index].users.list.map((users, index) => (
                                                    // eslint-disable-next-line react/no-array-index-key
                                                      <FastField name={`users.${index}`} key={index}>
                                                        {() => {
                                                          return (
                                                            <FormItem
                                                              className='ant-form-item-col disable-ant-form-margin'
                                                              name={`${name}.users.list[${index}]`}
                                                              showInitialErrorAfterTouched
                                                            >
                                                              <div className='da-d-flex'>
                                                                <Input
                                                                  fast
                                                                  className='da-mb-8'
                                                                  name={`${name}.users.list[${index}]`}
                                                                  placeholder='Specify user id'
                                                                  disabled={!canEditForm}
                                                                  onChange={(event) => {
                                                                    setFieldValue(`${name}.users.list[${index}]`, event.target.value.replace(/\s/g, ''));
                                                                  }}
                                                                />
                                                                {canEditForm && (
                                                                  <Popconfirm title="Sure to delete this user id?" onConfirm={() => remove(index)}>
                                                                    <Button
                                                                      style={{ margin: '3px 0 0 5px' }}
                                                                      size='small'
                                                                      danger
                                                                      icon={<DeleteOutlined />}
                                                                    />
                                                                  </Popconfirm>
                                                                )}
                                                              </div>
                                                            </FormItem>
                                                          );
                                                        }}
                                                      </FastField>
                                                    ))}
                                                    <Button
                                                      type='primary'
                                                      size='small'
                                                      disabled={!isEmpty(values.conditions[index]?.users?.list) ? values.conditions[index].users.list.map((item) => isEmpty(item)).includes(true) : !canEditForm}
                                                      icon={<RiAddFill />}
                                                      onClick={() => push('')}
                                                    >
                                                      Add user id
                                                    </Button>
                                                  </>
                                                );
                                              }}
                                            </FieldArray>
                                          </FormItem>
                                        </Col>
                                      </Row>
                                      <Row gutter={[ 16, 16 ]}>
                                        <Col xs={24} sm={24} md={16} lg={8}>
                                          <FormItem
                                            className='ant-form-item-col'
                                            name={`${name}.custom_target`}
                                            label="Custom target:"
                                          >
                                            <Input.TextArea
                                              fast
                                              name={`${name}.custom_target`}
                                              placeholder='Specify custom target'
                                              rows={7}
                                              showCount
                                              maxLength={255}
                                              disabled={!canEditForm}
                                            />
                                          </FormItem>
                                        </Col>
                                      </Row>
                                    </>
                                  );
                                }}
                              </FastField>
                            </Card>
                          )) : (
                            <>
                              {canEditForm && (
                                <Row align='end' className='da-mb-18'>
                                  <Button
                                    type='primary'
                                    icon={<RiAddFill />}
                                    onClick={() => push(AB_TEST_INITIAL_CONDITION_FIELD_VALUES)}
                                  >
                                    Add condition
                                  </Button>
                                </Row>
                              )}
                              <Card className='da-mt-18'>
                                <Empty description='Conditions data is empty' />
                              </Card>
                            </>
                          )}
                          {!isEmpty(values.conditions) && canEditForm && (
                            <Row align='end'>
                              <Button
                                type='primary'
                                icon={<RiAddFill />}
                                onClick={() => push(AB_TEST_INITIAL_CONDITION_FIELD_VALUES)}
                              >
                                Add condition
                              </Button>
                            </Row>
                          )}
                        </>
                      );
                    }}
                  </FieldArray>
                </Col>

                {isValidConditionsArr.includes(false) && (
                  <Col span={24}>
                    <Alert
                      className='da-mb-18'
                      message="Conditions notification"
                      description={`Please specify more condition ${clearedConditionsIndexMap} details.`}
                      type="error"
                    />
                  </Col>
                )}

                <FormCreateEditAbTestButtonsRow>
                  <FormCreateEditAbTestButtonWr>
                    <Button onClick={onCancel}>
                      Cancel
                    </Button>
                  </FormCreateEditAbTestButtonWr>
                  <FormCreateEditAbTestButtonWr>
                    <Popconfirm
                      disabled={!isEmpty(values.conditions)}
                      title="This ab-test would be worldwide, because conditions are not created."
                      onConfirm={() => {
                        onSubmit(values);
                      }}
                    >
                      <SubmitButton
                        style={{ width: '100%' }}
                        loading={isSubmitting}
                        disabled={!isValid || !dirty || isValidConditionsArr.includes(false)}
                        onClick={() => {
                          if (!isEmpty(values.conditions)) {
                            onSubmit(values);
                          }
                        }}
                      >
                        {isAbTestEdit ? 'Update' : 'Create'}
                      </SubmitButton>
                    </Popconfirm>
                  </FormCreateEditAbTestButtonWr>
                </FormCreateEditAbTestButtonsRow>
              </Row>
            </Form>
          </Spin>
        );
      }}
    </Formik>
  );
};

FormCreateEditAbTest.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({
    status: PropTypes.string,
  }).isRequired,
  formErrors: PropTypes.object,
  errors: PropTypes.arrayOf(PropTypes.shape({
    conditions: PropTypes.arrayOf(PropTypes.shape({
      input_os_versions_list: PropTypes.string,
      input_app_versions_list: PropTypes.string,
      input_ram_list: PropTypes.number,
      input_device_performance_list: PropTypes.number,
    })),
  })),
  values: PropTypes.shape({
    status: PropTypes.string,
    affiliation: PropTypes.string,
    countries: PropTypes.array,
    conditions: PropTypes.array,
    groups: PropTypes.array,
    device_types: PropTypes.array,
    languages: PropTypes.array,
    created_at: PropTypes.string,
    started_at: PropTypes.string,
    finished_at: PropTypes.string,
    condition_type: PropTypes.string,
    segments: PropTypes.array,
  }),
  isSubmitting: PropTypes.bool.isRequired,
  isAbTestEdit: PropTypes.bool,
  defaultAbTestStatus: PropTypes.string,
  segmentList: PropTypes.array.isRequired,
};

FormCreateEditAbTest.defaultProps = {
  formErrors: {},
  isAbTestEdit: false,
  defaultAbTestStatus: null,
  values: {
    status: '',
    affiliation: '',
    countries: [],
    device_types: [],
    languages: [],
    os_versions: { min: '', max: '' },
    platforms: {},
    app_versions: {},
    conditions: [],
    segments: [],
    created_at: null,
    started_at: null,
    finished_at: null,
    condition_type: '',
  },
};

export default FormCreateEditAbTest;
