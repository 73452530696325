import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import isEmpty from 'lodash/isEmpty';
import { setUrlParams } from '../../../lib/setUrlParams';
import segmentsService from '../services/segmentsService';



const initialState = {
  segments: [],
  segmentDetails: null,
  loading: false,
  detailsLoading: false,
  pagination: {},
  error: null,
};

export const getSegmentsList = createAsyncThunk(
  'segments/getList',
  async (values, { rejectWithValue }) => {
    try {
      const response = await segmentsService.getList(values);

      if (!isEmpty(values)) {
        setUrlParams(values);
      }

      return response;
    } catch (err) {
      if (!err.response) {
        throw new Error(`Oops something went wrong: ${err}`);
      }

      return rejectWithValue(err.response.data);
    }
  },
);

export const getSegmentDetails = createAsyncThunk(
  'segments/getDetails',
  async (params) => {
    return segmentsService.getDetails(params);
  },
);

export const deleteSegment = createAsyncThunk(
  'segments/delete',
  async (params) => {
    return segmentsService.deleteSegment(params);
  },
);

export const segmentsSlice = createSlice({
  name: 'segments',
  initialState,
  reducers: {
    resetSegmentsList: (state) => {
      state.segments = [];
    },
    resetSegmentDetails: (state) => {
      state.segmentDetails = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSegmentsList.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getSegmentsList.fulfilled, (state, action) => {
        state.loading = false;
        state.pagination = action.payload.meta.pagination;
        state.segments = action.payload.data;
      })
      .addCase(getSegmentsList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getSegmentDetails.pending, (state) => {
        state.detailsLoading = true;
        state.error = null;
      })
      .addCase(getSegmentDetails.fulfilled, (state, action) => {
        state.detailsLoading = false;
        state.segmentDetails = action.payload.data;
      })
      .addCase(getSegmentDetails.rejected, (state, action) => {
        state.detailsLoading = false;
        state.error = action.error.message;
      })
      .addCase(deleteSegment.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteSegment.fulfilled, (state, action) => {
        state.loading = false;
        state.segmentDetails = action.payload.data;
      })
      .addCase(deleteSegment.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const { resetSegmentsList, resetSegmentDetails } = segmentsSlice.actions;

// selectors
export const selectSegmentsLoading = (state) => state.segments.loading;
export const selectSegmentsList = (state) => state.segments.segments;
export const selectSegmentDetails = (state) => state.segments.segmentDetails;
export const selectSegmentDetailsLoading = (state) => state.segments.detailsLoading;
export const selectSegmentPagination = (state) => state.segments.pagination;

export default segmentsSlice.reducer;
