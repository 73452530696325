import React, { useState, useEffect } from 'react';
import { Breadcrumb, Card, Col, Layout, Row, Input, Button, Spin, Table, Tooltip } from 'antd';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import ReactJson from 'react-json-view';
import { EyeOutlined } from '@ant-design/icons';
import { useApp } from '../../../../app/context/AppContext';
import { mkRedirectToDashboardPath } from '../../../../lib/mkRedirectToDashboard';
import HasRights from '../../../../components/HasRights';
import { Permissions } from '../../../../const/permissions';
import { getConfigByHash, selectConfigHashData, selectConfigHashLoading, resetConfigHash } from '../../feature/configHashVisualizeSlice';
import urlPageConfigMixinDetails from '../../../../urls/urlPageConfigMixinDetails';
import urlPageConfigurationDetails from '../../../../urls/urlPageConfigurationDetails';



const { Content } = Layout;

const PageConfigHashVisualize = () => {
  const { projectSettings } = useApp();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [ hash, setHash ] = useState('');
  const configData = useSelector(selectConfigHashData);
  const isLoading = useSelector(selectConfigHashLoading);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const hashFromUrl = params.get('hash');

    if (hashFromUrl) {
      setHash(hashFromUrl);
      dispatch(getConfigByHash({ hash: hashFromUrl, project: projectSettings.project }));
    }
  }, []);

  const handleLoadConfig = () => {
    if (hash) {
      dispatch(resetConfigHash());

      navigate({
        pathname: location.pathname,
        search: `?hash=${hash}`,
      });
      dispatch(getConfigByHash({ hash, project: projectSettings.project }));
    }
  };

  const handleOpenConfig = () => {
    if (configData) {
      window.open(urlPageConfigurationDetails({ configurationId: configData.config_id }), '_blank');
    }
  };

  return (
    <HasRights allowedPermissions={[ Permissions.CONFIGS.CONFIG_VISUALIZE.VIEW_HASH ]}>
      <Col span={24}>
        <Row justify="space-between">
          <Breadcrumb separator=">">
            <Breadcrumb.Item>
              <Link to={mkRedirectToDashboardPath(projectSettings)}>
                Home
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>Config Hash Visualize</Breadcrumb.Item>
          </Breadcrumb>
        </Row>
      </Col>

      <Content className='layout-content'>
        <Row gutter={[ 16, 16 ]}>
          <Col span={24}>
            <Card title="Config Hash Visualize">
              <Row gutter={[ 16, 16 ]}>
                <Col flex="auto">
                  <Input
                    placeholder="Enter config hash..."
                    value={hash}
                    onChange={(el) => setHash(el.target.value)}
                  />
                </Col>
                <Col>
                  <Button
                    type="primary"
                    onClick={handleLoadConfig}
                    loading={isLoading}
                  >
                    Load
                  </Button>
                </Col>
                <Col>
                  <Button
                    type="default"
                    onClick={handleOpenConfig}
                    disabled={!configData}
                  >
                    Open config page
                  </Button>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
        {isLoading ? (
          <Row>
            <Col>
              <Spin />
            </Col>
          </Row>
        ) : configData && (
          <Row gutter={[ 16, 16 ]}>
            <Col span={12}>
              <Card title={configData.config_name}>
                <ReactJson
                  src={JSON.parse(configData.json)}
                  name={null}
                  theme="rjv-default"
                  collapsed={2}
                  displayDataTypes={false}
                  enableClipboard
                />
              </Card>
            </Col>
            <Col span={12}>
              <Card title="Mixins">
                <Table
                  dataSource={configData.mixins}
                  columns={[
                    {
                      title: 'Id',
                      dataIndex: 'id',
                      key: 'id',
                    },
                    {
                      title: 'Name',
                      dataIndex: 'name',
                      key: 'name',
                    },
                    {
                      title: 'Action',
                      key: 'actions',
                      width: 80,
                      render: (_, record) => (
                        <Tooltip placement="top" title={record.deleted_at ? 'Mixin was deleted' : 'Details'}>
                          <Button
                            size="small"
                            type='primary'
                            className='btn-with-side-margin'
                            disabled={record.deleted_at !== null}
                          >
                            <Link
                              target="_blank"
                              to={urlPageConfigMixinDetails({
                                configurationId: configData.config_id,
                                configurationName: configData.config_name,
                                mixinId: record.id,
                              })}
                            >
                              <EyeOutlined />
                            </Link>
                          </Button>
                        </Tooltip>
                      ),
                    },
                  ]}
                  rowClassName={(record) => record.deleted_at ? 'deleted-row' : ''}
                  pagination={false}
                />
              </Card>
            </Col>
          </Row>
        )}
      </Content>
    </HasRights>
  );
};

export default PageConfigHashVisualize;
