import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import Api from '../../../services/Api/Api';



const initialState = {
  data: null,
  loading: false,
  error: null,
};

export const getConfigByHash = createAsyncThunk(
  'configHash/get',
  async ({ hash, project }) => {
    return Api.get(`visualize/config/hash/${hash}`, { params: { project } });
  },
);

const configHashVisualizeSlice = createSlice({
  name: 'configHash',
  initialState,
  reducers: {
    resetConfigHash: (state) => {
      state.data = null;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getConfigByHash.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getConfigByHash.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload.data;
      })
      .addCase(getConfigByHash.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const { resetConfigHash } = configHashVisualizeSlice.actions;

export const selectConfigHashLoading = (state) => state.configHash.loading;
export const selectConfigHashData = (state) => state.configHash.data;
export const selectConfigHashError = (state) => state.configHash.error;

export default configHashVisualizeSlice.reducer;
