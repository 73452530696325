import React from 'react';
import { Badge, Button, Card, Descriptions, Tag, Tooltip } from 'antd';
import PropTypes from 'prop-types';
import capitalize from 'lodash/capitalize';
import { EditOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { hasRights, Permissions } from '../../../../const/permissions';
import { EMPTY_VALUE_PLACEHOLDER, renderDateField } from '../../../../const/system';



const SegmentDetailsCard = ({
  segment,
  loading,
}) => {
  return (
    <div className='card-table'>
      <Card
        title='Segment details'
        loading={loading}
        style={{ marginBottom: '10px' }}
        extra={[
          <div key='2'>
            {hasRights([ Permissions.SEGMENTS.UPDATE ]) && (
              <Tooltip
                placement="bottom"
                title='Edit segment'
              >
                <Button
                  size="small"
                  type='primary'
                  className='btn-with-side-margin warning-btn'
                  disabled={loading}
                >
                  <Link
                    to={`/segments/edit/${segment.name}`}
                  >
                    <EditOutlined />
                  </Link>
                </Button>
              </Tooltip>
            )}
          </div>,
        ]}
      >
        <Descriptions bordered column={1}>
          <Descriptions.Item label="Name:">
            {segment?.name || EMPTY_VALUE_PLACEHOLDER}
          </Descriptions.Item>
          <Descriptions.Item label="Status:">
            <Badge
              color={segment?.status === 'enabled' ? 'green' : 'red'}
              text={capitalize(segment?.status || '')}
            />
          </Descriptions.Item>
          <Descriptions.Item label="Total Users:">
            {segment?.users_count || EMPTY_VALUE_PLACEHOLDER}
          </Descriptions.Item>
          <Descriptions.Item label="Created at:">
            <Tag color="blue">
              {renderDateField(segment?.created_at)}
            </Tag>
          </Descriptions.Item>
          <Descriptions.Item label="Updated at:">
            <Tag color="geekblue">
              {renderDateField(segment?.updated_at)}
            </Tag>
          </Descriptions.Item>
        </Descriptions>
      </Card>
    </div>
  );
};

SegmentDetailsCard.propTypes = {
  segment: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  onDelete: PropTypes.func.isRequired,
  segmentName: PropTypes.string,
};

SegmentDetailsCard.defaultProps = {
  segmentName: '',
};

export default SegmentDetailsCard;
