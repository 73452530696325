import React, { useState } from 'react';
import { Button, Card, Input, Space } from 'antd';
import PropTypes from 'prop-types';
import { CheckCircleFilled, CloseCircleFilled } from '@ant-design/icons';
import segmentsService from '../../services/segmentsService';
import { useApp } from '../../../../app/context/AppContext';



const SegmentUserCheckCard = ({ segment, loading }) => {
  const [ uuid, setUuid ] = useState('');
  const [ checkResult, setCheckResult ] = useState(null);
  const [ checking, setChecking ] = useState(false);
  const { projectSettings } = useApp();

  const handleCheck = async () => {
    if (!uuid || !segment?.name) return;

    setCheckResult(null);
    setChecking(true);
    try {
      const response = await segmentsService.checkUser({
        segmentId: segment.name,
        uuid,
        project: projectSettings.projectKey,
      });

      setCheckResult(response.data.result);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error checking user:', error);
      setCheckResult(false);
    } finally {
      setChecking(false);
    }
  };

  return (
    <Card title="Check User in Segment" loading={loading} style={{ marginBottom: 16 }}>
      <Space style={{ width: '100%' }}>
        <Input
          placeholder="Enter Player's UUID"
          value={uuid}
          onChange={(event) => setUuid(event.target.value)}
          onPressEnter={handleCheck}
          style={{ width: 350 }}
          disabled={checking}
        />
        <Button
          type="primary"
          onClick={handleCheck}
          loading={checking}
          disabled={!uuid}
        >
          Check
        </Button>

        {checkResult !== null && (
          checkResult ? (
            <CheckCircleFilled style={{ color: '#52c41a', fontSize: '32px' }} />
          ) : (
            <CloseCircleFilled style={{ color: '#ff4d4f', fontSize: '32px' }} />
          )
        )}
      </Space>
    </Card>
  );
};

SegmentUserCheckCard.propTypes = {
  segment: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }),
  loading: PropTypes.bool.isRequired,
};

export default SegmentUserCheckCard;
